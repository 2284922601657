@keyframes animateChatFirstDot {
  0% {
    transform: translate(1.5px, 1.5px);
  }

  33.333% {
    animation-timing-function: cubic-bezier(.4, 0, .6, 1);
    transform: translate(1.5px, 1.5px);
  }

  40% {
    animation-timing-function: cubic-bezier(.4, 0, .6, 1);
    transform: translate(1.5px, -1.5px);
  }

  46.667% {
    transform: translate(1.5px, 1.5px);
  }

  100% {
    transform: translate(1.5px, 1.5px);
  }
}

@keyframes animateChatSecondDot {
  0% {
    transform: translate(7.5px, 1.5px);
  }

  37.778% {
    animation-timing-function: cubic-bezier(.4, 0, .6, 1);
    transform: translate(7.5px, 1.5px);
  }

  44.444% {
    animation-timing-function: cubic-bezier(.4, 0, .6, 1);
    transform: translate(7.5px, -1.5px);
  }

  51.111% {
    transform: translate(7.5px, 1.5px);
  }

  100% {
    transform: translate(7.5px, 1.5px);
  }
}

@keyframes animateChatThirdDot {
  0% {
    transform: translate(13.5px, 1.5px);
  }

  42.222% {
    animation-timing-function: cubic-bezier(.4, 0, .6, 1);
    transform: translate(13.5px, 1.5px);
  }

  46.667% {
    animation-timing-function: cubic-bezier(.4, 0, .6, 1);
    transform: translate(13.5px, -1.5px);
  }

  55.556% {
    transform: translate(13.5px, 1.5px);
  }

  100% {
    transform: translate(13.5px, 1.5px);
  }
}

.icon.showAnimation .firstDot {
  animation: 2s linear infinite both animateChatFirstDot;
}

.icon.showAnimation .secondDot {
  animation: 2s linear infinite both animateChatSecondDot;
}

.icon.showAnimation .thirdDot {
  animation: 2s linear infinite both animateChatThirdDot;
}
