:local .spinnerWrapper {
  z-index: 10020;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
}
:local .withDimBackground {
  background-color: rgba(0, 0, 0, 0.1);
}