@keyframes animateChatFirstDot {
  0% {
    transform: translate(1.5px, 1.5px);
  }
  33.333% {
    transform: translate(1.5px, 1.5px);
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  40% {
    transform: translate(1.5px, -1.5px);
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  46.667% {
    transform: translate(1.5px, 1.5px);
  }
  100% {
    transform: translate(1.5px, 1.5px);
  }
}
@keyframes animateChatSecondDot {
  0% {
    transform: translate(7.5px, 1.5px);
  }
  37.778% {
    transform: translate(7.5px, 1.5px);
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  44.444% {
    transform: translate(7.5px, -1.5px);
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  51.111% {
    transform: translate(7.5px, 1.5px);
  }
  100% {
    transform: translate(7.5px, 1.5px);
  }
}
@keyframes animateChatThirdDot {
  0% {
    transform: translate(13.5px, 1.5px);
  }
  42.222% {
    transform: translate(13.5px, 1.5px);
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  46.667% {
    transform: translate(13.5px, -1.5px);
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  55.556% {
    transform: translate(13.5px, 1.5px);
  }
  100% {
    transform: translate(13.5px, 1.5px);
  }
}
.icon.showAnimation .firstDot {
  animation: animateChatFirstDot 2s linear infinite both;
}
.icon.showAnimation .secondDot {
  animation: animateChatSecondDot 2s linear infinite both;
}
.icon.showAnimation .thirdDot {
  animation: animateChatThirdDot 2s linear infinite both;
}