.button {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  line-height: unset;
  box-shadow: 1px 3px 30px 0px rgba(0, 0, 0, 0.18);
  cursor: pointer;
  background-color: #2e5f6c;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 300ms ease;
  width: 54px;
  height: 54px;
  margin-left: auto;
}
@media (min-width: 1025px) {
  .button {
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    border-radius: 100px;
    width: auto;
    height: 48px;
    padding: 12px 18px;
    border: 2px solid #fff;
  }
}
.button:hover {
  background-color: #223f4c;
}
@media print {
  .button {
    display: none;
  }
}

.hidden {
  display: none;
}

.enter {
  animation: enterFromBottom 600ms cubic-bezier(0.44, 0, 0.12, 1.02);
}

.chatIcon {
  width: 24px;
  height: 24px;
}
@media (min-width: 1025px) {
  .chatIcon {
    width: 18px;
    height: 18px;
  }
}

.chatLabel {
  display: none;
}
@media (min-width: 1025px) {
  .chatLabel {
    display: block;
    user-select: none;
    margin-left: 9px;
  }
}

.container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  right: 14px;
  bottom: 14px;
  left: auto;
  top: auto;
  z-index: 200;
}
@media (min-width: 1025px) {
  .container {
    align-items: flex-start;
    right: 18px;
    bottom: 18px;
  }
}

@keyframes enterFromBottom {
  0% {
    opacity: 0;
    transform: translateY(8px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}