.link {
  line-height: unset;
  color: #000;
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: underline;
  transition: color .2s;
}

.link:hover {
  color: #444;
}

@media (hover: none) {
  .link:hover {
    color: #000;
  }
}

.standardLink {
  color: #000;
  cursor: pointer;
  text-decoration: underline;
  transition: color .2s;
}

.standardLink:hover {
  color: #444;
}

@media (hover: none) {
  .standardLink:hover {
    color: #000;
  }
}

.invertedLink {
  color: #444;
  cursor: pointer;
  text-decoration: none;
  transition: color .2s;
}

.invertedLink:hover {
  color: #444;
  text-decoration: underline;
}

.contrastLinkLightInverse {
  color: #fff;
  text-decoration: none;
}

.contrastLinkLightInverse:hover {
  color: #fff;
  text-decoration: underline;
}

.contrastLinkLight {
  color: #fff;
  text-decoration: none;
}

.contrastLinkLight a:hover, .contrastLinkLight:hover {
  color: #fff;
  text-decoration: underline;
}

.contrastLinkAlternate {
  color: #bbb;
  text-decoration: none;
}

.contrastLinkAlternate:hover {
  color: #bbb;
  text-decoration: underline;
}

.contrastLinkNoEffect {
  color: #fff;
  text-decoration: none;
}

.contrastLinkNoEffect:hover {
  color: #fff;
}

.standardLinkNoEffect {
  color: #000;
  text-decoration: none;
}

.standardLinkNoEffect:hover {
  color: #000;
}

.underline {
  text-decoration: underline;
}

.dashed {
  -webkit-text-decoration: underline dashed;
  text-decoration: underline dashed;
  text-decoration-thickness: .5px;
}

.none, .linkNoDecoration {
  text-decoration: none;
}
