:local .title {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  margin: 0;
}
:local .title.small {
  font-style: none;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.4;
}
:local .title.medium {
  font-style: none;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.4;
}
:local .title.large {
  font-style: none;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.4;
}
:local .title.xLarge {
  font-style: none;
  font-weight: normal;
  font-size: 28px;
  line-height: 1.4;
}
:local .title.xxLarge {
  font-style: none;
  font-weight: normal;
  font-size: 32px;
  line-height: 1.4;
}
:local .subtitle {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 300;
}
:local .subtitle.small {
  font-size: 12px;
  font-weight: 300;
  margin-top: 0;
}
:local .subtitle.medium {
  font-size: 14px;
  font-weight: 300;
  margin-top: 0;
}
:local .subtitle.large {
  font-size: 16px;
  font-weight: 300;
  margin-top: 9px;
}
:local .subtitle.xLarge, :local .subtitle.xxLarge {
  font-size: 18px;
  font-weight: 300;
  margin-top: 9px;
}
:local .subtitle.xxSmallSpacing {
  margin-top: 5px;
}
:local .subtitle.xSmallSpacing {
  margin-top: 9px;
}