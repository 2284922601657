:local .spinnerWrapper {
  z-index: 10020;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

:local .withDimBackground {
  background-color: #0000001a;
}
