:local .bottomBorder {
  background-color: #c2a661;
  width: 60px;
  height: 2px;
  margin: 0 auto;
}

:is(:local .bottomBorder.small, :local .bottomBorder.medium) {
  margin-top: 9px;
}

:is(:local .bottomBorder.large, :local .bottomBorder.xLarge, :local .bottomBorder.xxLarge) {
  margin-top: 18px;
}

:local .bottomBorder.isTrade {
  background-color: #0f8d88;
}
