*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
}

.container {
  width: 100%;
}

.dibsIcon {
  fill: currentColor;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.zAboveNav {
  z-index: 990;
}

.zAboveOverlay {
  z-index: 10030;
}

.zBelow {
  z-index: -10;
}

.zBelowNav {
  z-index: 960;
}

.zBump {
  z-index: 10;
}

.zFarBelow {
  z-index: -100;
}

.zHigh {
  z-index: 300;
}

.zLow {
  z-index: 100;
}

.zMiddle {
  z-index: 200;
}

.zNav {
  z-index: 980;
}

.zNone {
  z-index: 0;
}

.zOverlay {
  z-index: 10020;
}

.floatRight {
  float: right;
}

.floatLeft {
  float: left;
}

.floatNone {
  float: none;
}

.clearLeft {
  clear: left;
}

.clearRight {
  clear: right;
}

.clearBoth {
  clear: both;
}

.clearNone {
  clear: none;
}

.m0 {
  margin: 0;
}

.m1px {
  margin: 1px;
}

.m2px {
  margin: 2px;
}

.m3px {
  margin: 3px;
}

.mLarge {
  margin: 36px;
}

.mLarger {
  margin: 45px;
}

.mMedium {
  margin: 27px;
}

.mSmall {
  margin: 18px;
}

.mSmaller {
  margin: 14px;
}

.mXlarge {
  margin: 54px;
}

.mXsmall {
  margin: 9px;
}

.mXxlarge {
  margin: 72px;
}

.mXxsmall {
  margin: 5px;
}

.mAuto {
  margin: auto;
}

.mx0 {
  margin-left: 0;
  margin-right: 0;
}

.mx1px {
  margin-left: 1px;
  margin-right: 1px;
}

.mx2px {
  margin-left: 2px;
  margin-right: 2px;
}

.mx3px {
  margin-left: 3px;
  margin-right: 3px;
}

.mxLarge {
  margin-left: 36px;
  margin-right: 36px;
}

.mxLarger {
  margin-left: 45px;
  margin-right: 45px;
}

.mxMedium {
  margin-left: 27px;
  margin-right: 27px;
}

.mxSmall {
  margin-left: 18px;
  margin-right: 18px;
}

.mxSmaller {
  margin-left: 14px;
  margin-right: 14px;
}

.mxXlarge {
  margin-left: 54px;
  margin-right: 54px;
}

.mxXsmall {
  margin-left: 9px;
  margin-right: 9px;
}

.mxXxlarge {
  margin-left: 72px;
  margin-right: 72px;
}

.mxXxsmall {
  margin-left: 5px;
  margin-right: 5px;
}

.mxAuto {
  margin-left: auto;
  margin-right: auto;
}

.my0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my1px {
  margin-top: 1px;
  margin-bottom: 1px;
}

.my2px {
  margin-top: 2px;
  margin-bottom: 2px;
}

.my3px {
  margin-top: 3px;
  margin-bottom: 3px;
}

.myLarge {
  margin-top: 36px;
  margin-bottom: 36px;
}

.myLarger {
  margin-top: 45px;
  margin-bottom: 45px;
}

.myMedium {
  margin-top: 27px;
  margin-bottom: 27px;
}

.mySmall {
  margin-top: 18px;
  margin-bottom: 18px;
}

.mySmaller {
  margin-top: 14px;
  margin-bottom: 14px;
}

.myXlarge {
  margin-top: 54px;
  margin-bottom: 54px;
}

.myXsmall {
  margin-top: 9px;
  margin-bottom: 9px;
}

.myXxlarge {
  margin-top: 72px;
  margin-bottom: 72px;
}

.myXxsmall {
  margin-top: 5px;
  margin-bottom: 5px;
}

.myAuto {
  margin-top: auto;
  margin-bottom: auto;
}

.mb0 {
  margin-bottom: 0;
}

.mb1px {
  margin-bottom: 1px;
}

.mb2px {
  margin-bottom: 2px;
}

.mb3px {
  margin-bottom: 3px;
}

.mbLarge {
  margin-bottom: 36px;
}

.mbLarger {
  margin-bottom: 45px;
}

.mbMedium {
  margin-bottom: 27px;
}

.mbSmall {
  margin-bottom: 18px;
}

.mbSmaller {
  margin-bottom: 14px;
}

.mbXlarge {
  margin-bottom: 54px;
}

.mbXsmall {
  margin-bottom: 9px;
}

.mbXxlarge {
  margin-bottom: 72px;
}

.mbXxsmall {
  margin-bottom: 5px;
}

.mbAuto {
  margin-bottom: auto;
}

.me0 {
  margin-inline-end: 0;
}

.me1px {
  margin-inline-end: 1px;
}

.me2px {
  margin-inline-end: 2px;
}

.me3px {
  margin-inline-end: 3px;
}

.meLarge {
  margin-inline-end: 36px;
}

.meLarger {
  margin-inline-end: 45px;
}

.meMedium {
  margin-inline-end: 27px;
}

.meSmall {
  margin-inline-end: 18px;
}

.meSmaller {
  margin-inline-end: 14px;
}

.meXlarge {
  margin-inline-end: 54px;
}

.meXsmall {
  margin-inline-end: 9px;
}

.meXxlarge {
  margin-inline-end: 72px;
}

.meXxsmall {
  margin-inline-end: 5px;
}

.meAuto {
  margin-inline-end: auto;
}

.ml0 {
  margin-left: 0;
}

.ml1px {
  margin-left: 1px;
}

.ml2px {
  margin-left: 2px;
}

.ml3px {
  margin-left: 3px;
}

.mlLarge {
  margin-left: 36px;
}

.mlLarger {
  margin-left: 45px;
}

.mlMedium {
  margin-left: 27px;
}

.mlSmall {
  margin-left: 18px;
}

.mlSmaller {
  margin-left: 14px;
}

.mlXlarge {
  margin-left: 54px;
}

.mlXsmall {
  margin-left: 9px;
}

.mlXxlarge {
  margin-left: 72px;
}

.mlXxsmall {
  margin-left: 5px;
}

.mlAuto {
  margin-left: auto;
}

.mr0 {
  margin-right: 0;
}

.mr1px {
  margin-right: 1px;
}

.mr2px {
  margin-right: 2px;
}

.mr3px {
  margin-right: 3px;
}

.mrLarge {
  margin-right: 36px;
}

.mrLarger {
  margin-right: 45px;
}

.mrMedium {
  margin-right: 27px;
}

.mrSmall {
  margin-right: 18px;
}

.mrSmaller {
  margin-right: 14px;
}

.mrXlarge {
  margin-right: 54px;
}

.mrXsmall {
  margin-right: 9px;
}

.mrXxlarge {
  margin-right: 72px;
}

.mrXxsmall {
  margin-right: 5px;
}

.mrAuto {
  margin-right: auto;
}

.ms0 {
  margin-inline-start: 0;
}

.ms1px {
  margin-inline-start: 1px;
}

.ms2px {
  margin-inline-start: 2px;
}

.ms3px {
  margin-inline-start: 3px;
}

.msLarge {
  margin-inline-start: 36px;
}

.msLarger {
  margin-inline-start: 45px;
}

.msMedium {
  margin-inline-start: 27px;
}

.msSmall {
  margin-inline-start: 18px;
}

.msSmaller {
  margin-inline-start: 14px;
}

.msXlarge {
  margin-inline-start: 54px;
}

.msXsmall {
  margin-inline-start: 9px;
}

.msXxlarge {
  margin-inline-start: 72px;
}

.msXxsmall {
  margin-inline-start: 5px;
}

.msAuto {
  margin-inline-start: auto;
}

.mt0 {
  margin-top: 0;
}

.mt1px {
  margin-top: 1px;
}

.mt2px {
  margin-top: 2px;
}

.mt3px {
  margin-top: 3px;
}

.mtLarge {
  margin-top: 36px;
}

.mtLarger {
  margin-top: 45px;
}

.mtMedium {
  margin-top: 27px;
}

.mtSmall {
  margin-top: 18px;
}

.mtSmaller {
  margin-top: 14px;
}

.mtXlarge {
  margin-top: 54px;
}

.mtXsmall {
  margin-top: 9px;
}

.mtXxlarge {
  margin-top: 72px;
}

.mtXxsmall {
  margin-top: 5px;
}

.mtAuto {
  margin-top: auto;
}

.boxBorder {
  box-sizing: border-box;
}

.boxContent {
  box-sizing: content-box;
}

.lineClamp1 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.lineClamp2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.lineClamp3 {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.lineClamp4 {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.lineClamp5 {
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.lineClamp6 {
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.lineClampNone {
  -webkit-line-clamp: none;
  -webkit-box-orient: horizontal;
  display: block;
  overflow: visible;
}

.block {
  display: block;
}

.inlineBlock {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inlineFlex {
  display: inline-flex;
}

.table {
  display: table;
}

.inlineTable {
  display: inline-table;
}

.tableCaption {
  display: table-caption;
}

.tableCell {
  display: table-cell;
}

.tableColumn {
  display: table-column;
}

.tableColumnGroup {
  display: table-column-group;
}

.tableFooterGroup {
  display: table-footer-group;
}

.tableHeaderGroup {
  display: table-header-group;
}

.tableRowGroup {
  display: table-row-group;
}

.tableRow {
  display: table-row;
}

.flowRoot {
  display: flow-root;
}

.grid {
  display: grid;
}

.inlineGrid {
  display: inline-grid;
}

.contents {
  display: contents;
}

.listItem {
  display: list-item;
}

.hidden {
  display: none;
}

.aspectAuto {
  aspect-ratio: auto;
}

.aspectSquare {
  aspect-ratio: 1;
}

.aspectVideo {
  aspect-ratio: 16 / 9;
}

.h0 {
  height: 0;
}

.h100px {
  height: 100px;
}

.h10px {
  height: 10px;
}

.h14px {
  height: 14px;
}

.h16 {
  height: 16.6667%;
}

.h16px {
  height: 16px;
}

.h18px {
  height: 18px;
}

.h1px {
  height: 1px;
}

.h20 {
  height: 20%;
}

.h200px {
  height: 200px;
}

.h20px {
  height: 20px;
}

.h25 {
  height: 25%;
}

.h250px {
  height: 250px;
}

.h280px {
  height: 280px;
}

.h2px {
  height: 2px;
}

.h300px {
  height: 300px;
}

.h33 {
  height: 33.3333%;
}

.h3px {
  height: 3px;
}

.h40 {
  height: 40%;
}

.h400px {
  height: 400px;
}

.h41 {
  height: 41.6667%;
}

.h450px {
  height: 450px;
}

.h4px {
  height: 4px;
}

.h50 {
  height: 50%;
}

.h500px {
  height: 500px;
}

.h58 {
  height: 58.3333%;
}

.h5px {
  height: 5px;
}

.h60 {
  height: 60%;
}

.h600px {
  height: 600px;
}

.h60px {
  height: 60px;
}

.h66 {
  height: 66.6667%;
}

.h75 {
  height: 75%;
}

.h8 {
  height: 8.33333%;
}

.h80 {
  height: 80%;
}

.h83 {
  height: 83.3333%;
}

.h91 {
  height: 91.6667%;
}

.hLarge {
  height: 36px;
}

.hLarger {
  height: 45px;
}

.hMedium {
  height: 27px;
}

.hSmall {
  height: 18px;
}

.hSmaller {
  height: 14px;
}

.hXlarge {
  height: 54px;
}

.hXsmall {
  height: 9px;
}

.hXxlarge {
  height: 72px;
}

.hXxsmall {
  height: 5px;
}

.hAuto {
  height: auto;
}

.hFit {
  height: fit-content;
}

.hFull {
  height: 100%;
}

.hMax {
  height: max-content;
}

.hMin {
  height: min-content;
}

.hScreen {
  height: 100vh;
}

.hUnset {
  height: unset;
}

.maxH0 {
  max-height: 0;
}

.maxH100px {
  max-height: 100px;
}

.maxH10px {
  max-height: 10px;
}

.maxH14px {
  max-height: 14px;
}

.maxH16 {
  max-height: 16.6667%;
}

.maxH16px {
  max-height: 16px;
}

.maxH18px {
  max-height: 18px;
}

.maxH1px {
  max-height: 1px;
}

.maxH20 {
  max-height: 20%;
}

.maxH200px {
  max-height: 200px;
}

.maxH20px {
  max-height: 20px;
}

.maxH25 {
  max-height: 25%;
}

.maxH250px {
  max-height: 250px;
}

.maxH280px {
  max-height: 280px;
}

.maxH2px {
  max-height: 2px;
}

.maxH300px {
  max-height: 300px;
}

.maxH33 {
  max-height: 33.3333%;
}

.maxH3px {
  max-height: 3px;
}

.maxH40 {
  max-height: 40%;
}

.maxH400px {
  max-height: 400px;
}

.maxH41 {
  max-height: 41.6667%;
}

.maxH450px {
  max-height: 450px;
}

.maxH4px {
  max-height: 4px;
}

.maxH50 {
  max-height: 50%;
}

.maxH500px {
  max-height: 500px;
}

.maxH58 {
  max-height: 58.3333%;
}

.maxH5px {
  max-height: 5px;
}

.maxH60 {
  max-height: 60%;
}

.maxH600px {
  max-height: 600px;
}

.maxH60px {
  max-height: 60px;
}

.maxH66 {
  max-height: 66.6667%;
}

.maxH75 {
  max-height: 75%;
}

.maxH8 {
  max-height: 8.33333%;
}

.maxH80 {
  max-height: 80%;
}

.maxH83 {
  max-height: 83.3333%;
}

.maxH91 {
  max-height: 91.6667%;
}

.maxHLarge {
  max-height: 36px;
}

.maxHLarger {
  max-height: 45px;
}

.maxHMedium {
  max-height: 27px;
}

.maxHSmall {
  max-height: 18px;
}

.maxHSmaller {
  max-height: 14px;
}

.maxHXlarge {
  max-height: 54px;
}

.maxHXsmall {
  max-height: 9px;
}

.maxHXxlarge {
  max-height: 72px;
}

.maxHXxsmall {
  max-height: 5px;
}

.maxHAuto {
  max-height: auto;
}

.maxHFit {
  max-height: fit-content;
}

.maxHFull {
  max-height: 100%;
}

.maxHMax {
  max-height: max-content;
}

.maxHMin {
  max-height: min-content;
}

.maxHScreen {
  max-height: 100vh;
}

.maxHUnset {
  max-height: unset;
}

.minH0 {
  min-height: 0;
}

.minH100px {
  min-height: 100px;
}

.minH10px {
  min-height: 10px;
}

.minH14px {
  min-height: 14px;
}

.minH16 {
  min-height: 16.6667%;
}

.minH16px {
  min-height: 16px;
}

.minH18px {
  min-height: 18px;
}

.minH1px {
  min-height: 1px;
}

.minH20 {
  min-height: 20%;
}

.minH200px {
  min-height: 200px;
}

.minH20px {
  min-height: 20px;
}

.minH25 {
  min-height: 25%;
}

.minH250px {
  min-height: 250px;
}

.minH280px {
  min-height: 280px;
}

.minH2px {
  min-height: 2px;
}

.minH300px {
  min-height: 300px;
}

.minH33 {
  min-height: 33.3333%;
}

.minH3px {
  min-height: 3px;
}

.minH40 {
  min-height: 40%;
}

.minH400px {
  min-height: 400px;
}

.minH41 {
  min-height: 41.6667%;
}

.minH450px {
  min-height: 450px;
}

.minH4px {
  min-height: 4px;
}

.minH50 {
  min-height: 50%;
}

.minH500px {
  min-height: 500px;
}

.minH58 {
  min-height: 58.3333%;
}

.minH5px {
  min-height: 5px;
}

.minH60 {
  min-height: 60%;
}

.minH600px {
  min-height: 600px;
}

.minH60px {
  min-height: 60px;
}

.minH66 {
  min-height: 66.6667%;
}

.minH75 {
  min-height: 75%;
}

.minH8 {
  min-height: 8.33333%;
}

.minH80 {
  min-height: 80%;
}

.minH83 {
  min-height: 83.3333%;
}

.minH91 {
  min-height: 91.6667%;
}

.minHLarge {
  min-height: 36px;
}

.minHLarger {
  min-height: 45px;
}

.minHMedium {
  min-height: 27px;
}

.minHSmall {
  min-height: 18px;
}

.minHSmaller {
  min-height: 14px;
}

.minHXlarge {
  min-height: 54px;
}

.minHXsmall {
  min-height: 9px;
}

.minHXxlarge {
  min-height: 72px;
}

.minHXxsmall {
  min-height: 5px;
}

.minHAuto {
  min-height: auto;
}

.minHFit {
  min-height: fit-content;
}

.minHFull {
  min-height: 100%;
}

.minHMax {
  min-height: max-content;
}

.minHMin {
  min-height: min-content;
}

.minHScreen {
  min-height: 100vh;
}

.minHUnset {
  min-height: unset;
}

.w0 {
  width: 0;
}

.w100px {
  width: 100px;
}

.w10px {
  width: 10px;
}

.w1440px {
  width: 1440px;
}

.w14px {
  width: 14px;
}

.w16 {
  width: 16.6667%;
}

.w16px {
  width: 16px;
}

.w18px {
  width: 18px;
}

.w1px {
  width: 1px;
}

.w20 {
  width: 20%;
}

.w200px {
  width: 200px;
}

.w20px {
  width: 20px;
}

.w25 {
  width: 25%;
}

.w250px {
  width: 250px;
}

.w280px {
  width: 280px;
}

.w2px {
  width: 2px;
}

.w300px {
  width: 300px;
}

.w33 {
  width: 33.3333%;
}

.w3px {
  width: 3px;
}

.w40 {
  width: 40%;
}

.w400px {
  width: 400px;
}

.w41 {
  width: 41.6667%;
}

.w450px {
  width: 450px;
}

.w4px {
  width: 4px;
}

.w50 {
  width: 50%;
}

.w500px {
  width: 500px;
}

.w58 {
  width: 58.3333%;
}

.w5px {
  width: 5px;
}

.w60 {
  width: 60%;
}

.w600px {
  width: 600px;
}

.w60px {
  width: 60px;
}

.w66 {
  width: 66.6667%;
}

.w75 {
  width: 75%;
}

.w8 {
  width: 8.33333%;
}

.w80 {
  width: 80%;
}

.w83 {
  width: 83.3333%;
}

.w91 {
  width: 91.6667%;
}

.wLarge {
  width: 36px;
}

.wLarger {
  width: 45px;
}

.wMedium {
  width: 27px;
}

.wSmall {
  width: 18px;
}

.wSmaller {
  width: 14px;
}

.wXlarge {
  width: 54px;
}

.wXsmall {
  width: 9px;
}

.wXxlarge {
  width: 72px;
}

.wXxsmall {
  width: 5px;
}

.wAuto {
  width: auto;
}

.wFit {
  width: fit-content;
}

.wFull {
  width: 100%;
}

.wMax {
  width: max-content;
}

.wMin {
  width: min-content;
}

.wScreen {
  width: 100vw;
}

.wUnset {
  width: unset;
}

.minW0 {
  min-width: 0;
}

.minW100px {
  min-width: 100px;
}

.minW10px {
  min-width: 10px;
}

.minW1440px {
  min-width: 1440px;
}

.minW14px {
  min-width: 14px;
}

.minW16 {
  min-width: 16.6667%;
}

.minW16px {
  min-width: 16px;
}

.minW18px {
  min-width: 18px;
}

.minW1px {
  min-width: 1px;
}

.minW20 {
  min-width: 20%;
}

.minW200px {
  min-width: 200px;
}

.minW20px {
  min-width: 20px;
}

.minW25 {
  min-width: 25%;
}

.minW250px {
  min-width: 250px;
}

.minW280px {
  min-width: 280px;
}

.minW2px {
  min-width: 2px;
}

.minW300px {
  min-width: 300px;
}

.minW33 {
  min-width: 33.3333%;
}

.minW3px {
  min-width: 3px;
}

.minW40 {
  min-width: 40%;
}

.minW400px {
  min-width: 400px;
}

.minW41 {
  min-width: 41.6667%;
}

.minW450px {
  min-width: 450px;
}

.minW4px {
  min-width: 4px;
}

.minW50 {
  min-width: 50%;
}

.minW500px {
  min-width: 500px;
}

.minW58 {
  min-width: 58.3333%;
}

.minW5px {
  min-width: 5px;
}

.minW60 {
  min-width: 60%;
}

.minW600px {
  min-width: 600px;
}

.minW60px {
  min-width: 60px;
}

.minW66 {
  min-width: 66.6667%;
}

.minW75 {
  min-width: 75%;
}

.minW8 {
  min-width: 8.33333%;
}

.minW80 {
  min-width: 80%;
}

.minW83 {
  min-width: 83.3333%;
}

.minW91 {
  min-width: 91.6667%;
}

.minWLarge {
  min-width: 36px;
}

.minWLarger {
  min-width: 45px;
}

.minWMedium {
  min-width: 27px;
}

.minWSmall {
  min-width: 18px;
}

.minWSmaller {
  min-width: 14px;
}

.minWXlarge {
  min-width: 54px;
}

.minWXsmall {
  min-width: 9px;
}

.minWXxlarge {
  min-width: 72px;
}

.minWXxsmall {
  min-width: 5px;
}

.minWAuto {
  min-width: auto;
}

.minWFit {
  min-width: fit-content;
}

.minWFull {
  min-width: 100%;
}

.minWMax {
  min-width: max-content;
}

.minWMin {
  min-width: min-content;
}

.minWScreen {
  min-width: 100vw;
}

.minWUnset {
  min-width: unset;
}

.maxW0 {
  max-width: 0;
}

.maxW100px {
  max-width: 100px;
}

.maxW10px {
  max-width: 10px;
}

.maxW1440px {
  max-width: 1440px;
}

.maxW14px {
  max-width: 14px;
}

.maxW16 {
  max-width: 16.6667%;
}

.maxW16px {
  max-width: 16px;
}

.maxW18px {
  max-width: 18px;
}

.maxW1px {
  max-width: 1px;
}

.maxW20 {
  max-width: 20%;
}

.maxW200px {
  max-width: 200px;
}

.maxW20px {
  max-width: 20px;
}

.maxW25 {
  max-width: 25%;
}

.maxW250px {
  max-width: 250px;
}

.maxW280px {
  max-width: 280px;
}

.maxW2px {
  max-width: 2px;
}

.maxW300px {
  max-width: 300px;
}

.maxW33 {
  max-width: 33.3333%;
}

.maxW3px {
  max-width: 3px;
}

.maxW40 {
  max-width: 40%;
}

.maxW400px {
  max-width: 400px;
}

.maxW41 {
  max-width: 41.6667%;
}

.maxW450px {
  max-width: 450px;
}

.maxW4px {
  max-width: 4px;
}

.maxW50 {
  max-width: 50%;
}

.maxW500px {
  max-width: 500px;
}

.maxW58 {
  max-width: 58.3333%;
}

.maxW5px {
  max-width: 5px;
}

.maxW60 {
  max-width: 60%;
}

.maxW600px {
  max-width: 600px;
}

.maxW60px {
  max-width: 60px;
}

.maxW66 {
  max-width: 66.6667%;
}

.maxW75 {
  max-width: 75%;
}

.maxW8 {
  max-width: 8.33333%;
}

.maxW80 {
  max-width: 80%;
}

.maxW83 {
  max-width: 83.3333%;
}

.maxW91 {
  max-width: 91.6667%;
}

.maxWLarge {
  max-width: 36px;
}

.maxWLarger {
  max-width: 45px;
}

.maxWMedium {
  max-width: 27px;
}

.maxWSmall {
  max-width: 18px;
}

.maxWSmaller {
  max-width: 14px;
}

.maxWXlarge {
  max-width: 54px;
}

.maxWXsmall {
  max-width: 9px;
}

.maxWXxlarge {
  max-width: 72px;
}

.maxWXxsmall {
  max-width: 5px;
}

.maxWAuto {
  max-width: auto;
}

.maxWFit {
  max-width: fit-content;
}

.maxWFull {
  max-width: 100%;
}

.maxWMax {
  max-width: max-content;
}

.maxWMin {
  max-width: min-content;
}

.maxWScreen {
  max-width: 100vw;
}

.maxWUnset {
  max-width: unset;
}

.flex1 {
  flex: 1;
}

.flexAuto {
  flex: auto;
}

.flexInitial {
  flex: 0 auto;
}

.flexNone {
  flex: none;
}

.flexShrink {
  flex-shrink: 1;
}

.flexShrink0 {
  flex-shrink: 0;
}

.shrink {
  flex-shrink: 1;
}

.shrink0 {
  flex-shrink: 0;
}

.flexGrow {
  flex-grow: 1;
}

.flexGrow0 {
  flex-grow: 0;
}

.grow {
  flex-grow: 1;
}

.grow0 {
  flex-grow: 0;
}

.basis0 {
  flex-basis: 0;
}

.basis8 {
  flex-basis: 8.33333%;
}

.basis50 {
  flex-basis: 50%;
}

.basis33 {
  flex-basis: 33.3333%;
}

.basis25 {
  flex-basis: 25%;
}

.basis20 {
  flex-basis: 20%;
}

.basis17 {
  flex-basis: 16.6667%;
}

.basis83 {
  flex-basis: 83.3333%;
}

.basis92 {
  flex-basis: 91.6667%;
}

.basis1px {
  flex-basis: 1px;
}

.basis67 {
  flex-basis: 66.6667%;
}

.basis40 {
  flex-basis: 40%;
}

.basis2px {
  flex-basis: 2px;
}

.basis75 {
  flex-basis: 75%;
}

.basis60 {
  flex-basis: 60%;
}

.basis3px {
  flex-basis: 3px;
}

.basis80 {
  flex-basis: 80%;
}

.basis42 {
  flex-basis: 41.6667%;
}

.basis58 {
  flex-basis: 58.3333%;
}

.basisLarge {
  flex-basis: 36px;
}

.basisLarger {
  flex-basis: 45px;
}

.basisMedium {
  flex-basis: 27px;
}

.basisSmall {
  flex-basis: 18px;
}

.basisSmaller {
  flex-basis: 14px;
}

.basisXlarge {
  flex-basis: 54px;
}

.basisXsmall {
  flex-basis: 9px;
}

.basisXxlarge {
  flex-basis: 72px;
}

.basisXxsmall {
  flex-basis: 5px;
}

.basisAuto {
  flex-basis: auto;
}

.basisFull {
  flex-basis: 100%;
}

.tableAuto {
  table-layout: auto;
}

.tableFixed {
  table-layout: fixed;
}

.captionTop {
  caption-side: top;
}

.captionBottom {
  caption-side: bottom;
}

.borderSpacing0 {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacing1px {
  --tw-border-spacing-x: 1px;
  --tw-border-spacing-y: 1px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacing2px {
  --tw-border-spacing-x: 2px;
  --tw-border-spacing-y: 2px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacing3px {
  --tw-border-spacing-x: 3px;
  --tw-border-spacing-y: 3px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingLarge {
  --tw-border-spacing-x: 36px;
  --tw-border-spacing-y: 36px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingLarger {
  --tw-border-spacing-x: 45px;
  --tw-border-spacing-y: 45px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingMedium {
  --tw-border-spacing-x: 27px;
  --tw-border-spacing-y: 27px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingSmall {
  --tw-border-spacing-x: 18px;
  --tw-border-spacing-y: 18px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingSmaller {
  --tw-border-spacing-x: 14px;
  --tw-border-spacing-y: 14px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingXlarge {
  --tw-border-spacing-x: 54px;
  --tw-border-spacing-y: 54px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingXsmall {
  --tw-border-spacing-x: 9px;
  --tw-border-spacing-y: 9px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingXxlarge {
  --tw-border-spacing-x: 72px;
  --tw-border-spacing-y: 72px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingXxsmall {
  --tw-border-spacing-x: 5px;
  --tw-border-spacing-y: 5px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingX0 {
  --tw-border-spacing-x: 0;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingX1px {
  --tw-border-spacing-x: 1px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingX2px {
  --tw-border-spacing-x: 2px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingX3px {
  --tw-border-spacing-x: 3px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingXLarge {
  --tw-border-spacing-x: 36px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingXLarger {
  --tw-border-spacing-x: 45px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingXMedium {
  --tw-border-spacing-x: 27px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingXSmall {
  --tw-border-spacing-x: 18px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingXSmaller {
  --tw-border-spacing-x: 14px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingXXlarge {
  --tw-border-spacing-x: 54px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingXXsmall {
  --tw-border-spacing-x: 9px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingXXxlarge {
  --tw-border-spacing-x: 72px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingXXxsmall {
  --tw-border-spacing-x: 5px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingY0 {
  --tw-border-spacing-y: 0;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingY1px {
  --tw-border-spacing-y: 1px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingY2px {
  --tw-border-spacing-y: 2px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingY3px {
  --tw-border-spacing-y: 3px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingYLarge {
  --tw-border-spacing-y: 36px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingYLarger {
  --tw-border-spacing-y: 45px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingYMedium {
  --tw-border-spacing-y: 27px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingYSmall {
  --tw-border-spacing-y: 18px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingYSmaller {
  --tw-border-spacing-y: 14px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingYXlarge {
  --tw-border-spacing-y: 54px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingYXsmall {
  --tw-border-spacing-y: 9px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingYXxlarge {
  --tw-border-spacing-y: 72px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.borderSpacingYXxsmall {
  --tw-border-spacing-y: 5px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.cursorAlias {
  cursor: alias;
}

.cursorAllScroll {
  cursor: all-scroll;
}

.cursorAuto {
  cursor: auto;
}

.cursorCell {
  cursor: cell;
}

.cursorColResize {
  cursor: col-resize;
}

.cursorContextMenu {
  cursor: context-menu;
}

.cursorCopy {
  cursor: copy;
}

.cursorCrosshair {
  cursor: crosshair;
}

.cursorDefault {
  cursor: default;
}

.cursorEResize {
  cursor: e-resize;
}

.cursorEwResize {
  cursor: ew-resize;
}

.cursorGrab {
  cursor: grab;
}

.cursorGrabbing {
  cursor: grabbing;
}

.cursorHelp {
  cursor: help;
}

.cursorMove {
  cursor: move;
}

.cursorNResize {
  cursor: n-resize;
}

.cursorNeResize {
  cursor: ne-resize;
}

.cursorNeswResize {
  cursor: nesw-resize;
}

.cursorNoDrop {
  cursor: no-drop;
}

.cursorNone {
  cursor: none;
}

.cursorNotAllowed {
  cursor: not-allowed;
}

.cursorNsResize {
  cursor: ns-resize;
}

.cursorNwResize {
  cursor: nw-resize;
}

.cursorNwseResize {
  cursor: nwse-resize;
}

.cursorPointer {
  cursor: pointer;
}

.cursorProgress {
  cursor: progress;
}

.cursorRowResize {
  cursor: row-resize;
}

.cursorSResize {
  cursor: s-resize;
}

.cursorSeResize {
  cursor: se-resize;
}

.cursorSwResize {
  cursor: sw-resize;
}

.cursorText {
  cursor: text;
}

.cursorVerticalText {
  cursor: vertical-text;
}

.cursorWResize {
  cursor: w-resize;
}

.cursorWait {
  cursor: wait;
}

.cursorZoomIn {
  cursor: zoom-in;
}

.cursorZoomOut {
  cursor: zoom-out;
}

.listImageNone {
  list-style-image: none;
}

.breakBeforeAuto {
  -moz-column-break-before: auto;
  break-before: auto;
}

.breakBeforeAvoid {
  -moz-column-break-before: avoid;
  break-before: avoid;
}

.breakBeforeAll {
  -moz-column-break-before: all;
  break-before: all;
}

.breakBeforeAvoidPage {
  -moz-column-break-before: avoid;
  break-before: avoid-page;
}

.breakBeforePage {
  -moz-column-break-before: page;
  break-before: page;
}

.breakBeforeLeft {
  -moz-column-break-before: left;
  break-before: left;
}

.breakBeforeRight {
  -moz-column-break-before: right;
  break-before: right;
}

.breakBeforeColumn {
  -moz-column-break-before: column;
  break-before: column;
}

.breakInsideAuto {
  -moz-column-break-inside: auto;
  break-inside: auto;
}

.breakInsideAvoid {
  -moz-column-break-inside: avoid;
  break-inside: avoid;
}

.breakInsideAvoidPage {
  break-inside: avoid-page;
}

.breakInsideAvoidColumn {
  -moz-column-break-inside: avoid;
  break-inside: avoid-column;
}

.breakAfterAuto {
  -moz-column-break-after: auto;
  break-after: auto;
}

.breakAfterAvoid {
  -moz-column-break-after: avoid;
  break-after: avoid;
}

.breakAfterAll {
  -moz-column-break-after: all;
  break-after: all;
}

.breakAfterAvoidPage {
  -moz-column-break-after: avoid;
  break-after: avoid-page;
}

.breakAfterPage {
  -moz-column-break-after: page;
  break-after: page;
}

.breakAfterLeft {
  -moz-column-break-after: left;
  break-after: left;
}

.breakAfterRight {
  -moz-column-break-after: right;
  break-after: right;
}

.breakAfterColumn {
  -moz-column-break-after: column;
  break-after: column;
}

.flexRow {
  flex-direction: row;
}

.flexRowReverse {
  flex-direction: row-reverse;
}

.flexCol {
  flex-direction: column;
}

.flexColReverse {
  flex-direction: column-reverse;
}

.flexWrap {
  flex-wrap: wrap;
}

.flexWrapReverse {
  flex-wrap: wrap-reverse;
}

.flexNowrap {
  flex-wrap: nowrap;
}

.placeContentCenter {
  place-content: center;
}

.placeContentStart {
  place-content: start;
}

.placeContentEnd {
  place-content: end;
}

.placeContentBetween {
  place-content: space-between;
}

.placeContentAround {
  place-content: space-around;
}

.placeContentEvenly {
  place-content: space-evenly;
}

.placeContentBaseline {
  place-content: baseline start;
}

.placeContentStretch {
  place-content: stretch;
}

.placeItemsStart {
  place-items: start;
}

.placeItemsEnd {
  place-items: end;
}

.placeItemsCenter {
  place-items: center;
}

.placeItemsBaseline {
  place-items: baseline;
}

.placeItemsStretch {
  place-items: stretch stretch;
}

.contentNormal {
  align-content: normal;
}

.contentCenter {
  align-content: center;
}

.contentStart {
  align-content: flex-start;
}

.contentEnd {
  align-content: flex-end;
}

.contentBetween {
  align-content: space-between;
}

.contentAround {
  align-content: space-around;
}

.contentEvenly {
  align-content: space-evenly;
}

.contentBaseline {
  align-content: baseline;
}

.contentStretch {
  align-content: stretch;
}

.itemsStart {
  align-items: flex-start;
}

.itemsEnd {
  align-items: flex-end;
}

.itemsCenter {
  align-items: center;
}

.itemsBaseline {
  align-items: baseline;
}

.itemsStretch {
  align-items: stretch;
}

.justifyNormal {
  justify-content: normal;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.justifyCenter {
  justify-content: center;
}

.justifyBetween {
  justify-content: space-between;
}

.justifyAround {
  justify-content: space-around;
}

.justifyEvenly {
  justify-content: space-evenly;
}

.justifyStretch {
  justify-content: stretch;
}

.justifyItemsStart {
  justify-items: start;
}

.justifyItemsEnd {
  justify-items: end;
}

.justifyItemsCenter {
  justify-items: center;
}

.justifyItemsStretch {
  justify-items: stretch;
}

.gap0 {
  gap: 0;
}

.gap1px {
  gap: 1px;
}

.gap2px {
  gap: 2px;
}

.gap3px {
  gap: 3px;
}

.gapLarge {
  gap: 36px;
}

.gapLarger {
  gap: 45px;
}

.gapMedium {
  gap: 27px;
}

.gapSmall {
  gap: 18px;
}

.gapSmaller {
  gap: 14px;
}

.gapXlarge {
  gap: 54px;
}

.gapXsmall {
  gap: 9px;
}

.gapXxlarge {
  gap: 72px;
}

.gapXxsmall {
  gap: 5px;
}

.gapX0 {
  -moz-column-gap: 0;
  column-gap: 0;
}

.gapX1px {
  -moz-column-gap: 1px;
  column-gap: 1px;
}

.gapX2px {
  -moz-column-gap: 2px;
  column-gap: 2px;
}

.gapX3px {
  -moz-column-gap: 3px;
  column-gap: 3px;
}

.gapXLarge {
  -moz-column-gap: 36px;
  column-gap: 36px;
}

.gapXLarger {
  -moz-column-gap: 45px;
  column-gap: 45px;
}

.gapXMedium {
  -moz-column-gap: 27px;
  column-gap: 27px;
}

.gapXSmall {
  -moz-column-gap: 18px;
  column-gap: 18px;
}

.gapXSmaller {
  -moz-column-gap: 14px;
  column-gap: 14px;
}

.gapXXlarge {
  -moz-column-gap: 54px;
  column-gap: 54px;
}

.gapXXsmall {
  -moz-column-gap: 9px;
  column-gap: 9px;
}

.gapXXxlarge {
  -moz-column-gap: 72px;
  column-gap: 72px;
}

.gapXXxsmall {
  -moz-column-gap: 5px;
  column-gap: 5px;
}

.gapY0 {
  row-gap: 0;
}

.gapY1px {
  row-gap: 1px;
}

.gapY2px {
  row-gap: 2px;
}

.gapY3px {
  row-gap: 3px;
}

.gapYLarge {
  row-gap: 36px;
}

.gapYLarger {
  row-gap: 45px;
}

.gapYMedium {
  row-gap: 27px;
}

.gapYSmall {
  row-gap: 18px;
}

.gapYSmaller {
  row-gap: 14px;
}

.gapYXlarge {
  row-gap: 54px;
}

.gapYXsmall {
  row-gap: 9px;
}

.gapYXxlarge {
  row-gap: 72px;
}

.gapYXxsmall {
  row-gap: 5px;
}

.spaceX0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}

.spaceX1px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1px * var(--tw-space-x-reverse));
  margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
}

.spaceX2px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2px * var(--tw-space-x-reverse));
  margin-left: calc(2px * calc(1 - var(--tw-space-x-reverse)));
}

.spaceX3px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3px * var(--tw-space-x-reverse));
  margin-left: calc(3px * calc(1 - var(--tw-space-x-reverse)));
}

.spaceXLarge > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(36px * var(--tw-space-x-reverse));
  margin-left: calc(36px * calc(1 - var(--tw-space-x-reverse)));
}

.spaceXLarger > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(45px * var(--tw-space-x-reverse));
  margin-left: calc(45px * calc(1 - var(--tw-space-x-reverse)));
}

.spaceXMedium > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(27px * var(--tw-space-x-reverse));
  margin-left: calc(27px * calc(1 - var(--tw-space-x-reverse)));
}

.spaceXSmall > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(18px * var(--tw-space-x-reverse));
  margin-left: calc(18px * calc(1 - var(--tw-space-x-reverse)));
}

.spaceXSmaller > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(14px * var(--tw-space-x-reverse));
  margin-left: calc(14px * calc(1 - var(--tw-space-x-reverse)));
}

.spaceXXlarge > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(54px * var(--tw-space-x-reverse));
  margin-left: calc(54px * calc(1 - var(--tw-space-x-reverse)));
}

.spaceXXsmall > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(9px * var(--tw-space-x-reverse));
  margin-left: calc(9px * calc(1 - var(--tw-space-x-reverse)));
}

.spaceXXxlarge > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(72px * var(--tw-space-x-reverse));
  margin-left: calc(72px * calc(1 - var(--tw-space-x-reverse)));
}

.spaceXXxsmall > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(5px * var(--tw-space-x-reverse));
  margin-left: calc(5px * calc(1 - var(--tw-space-x-reverse)));
}

.spaceY0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}

.spaceY1px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1px * var(--tw-space-y-reverse));
}

.spaceY2px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2px * var(--tw-space-y-reverse));
}

.spaceY3px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3px * var(--tw-space-y-reverse));
}

.spaceYLarge > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(36px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(36px * var(--tw-space-y-reverse));
}

.spaceYLarger > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(45px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(45px * var(--tw-space-y-reverse));
}

.spaceYMedium > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(27px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(27px * var(--tw-space-y-reverse));
}

.spaceYSmall > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(18px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(18px * var(--tw-space-y-reverse));
}

.spaceYSmaller > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(14px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(14px * var(--tw-space-y-reverse));
}

.spaceYXlarge > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(54px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(54px * var(--tw-space-y-reverse));
}

.spaceYXsmall > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(9px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(9px * var(--tw-space-y-reverse));
}

.spaceYXxlarge > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(72px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(72px * var(--tw-space-y-reverse));
}

.spaceYXxsmall > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(5px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(5px * var(--tw-space-y-reverse));
}

.spaceYReverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 1;
}

.spaceXReverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1;
}

.divideX > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divideX0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(0px * var(--tw-divide-x-reverse));
  border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
}

.divideX2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(2px * var(--tw-divide-x-reverse));
  border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
}

.divideX4 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(4px * var(--tw-divide-x-reverse));
  border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
}

.divideX8 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(8px * var(--tw-divide-x-reverse));
  border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
}

.divideY > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divideY0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
}

.divideY2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
}

.divideY4 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
}

.divideY8 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
}

.divideYReverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 1;
}

.divideXReverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 1;
}

.divideSolid > :not([hidden]) ~ :not([hidden]) {
  border-style: solid;
}

.divideDashed > :not([hidden]) ~ :not([hidden]) {
  border-style: dashed;
}

.divideDotted > :not([hidden]) ~ :not([hidden]) {
  border-style: dotted;
}

.divideDouble > :not([hidden]) ~ :not([hidden]) {
  border-style: double;
}

.divideNone > :not([hidden]) ~ :not([hidden]) {
  border-style: none;
}

.divideAlabaster > :not([hidden]) ~ :not([hidden]) {
  border-color: #fbfbfb;
}

.divideAlertprimary > :not([hidden]) ~ :not([hidden]) {
  border-color: #c00;
}

.divideAlertsecondary > :not([hidden]) ~ :not([hidden]) {
  border-color: #950808;
}

.divideAlerttertiary > :not([hidden]) ~ :not([hidden]) {
  border-color: #fedcde;
}

.divideBlue400 > :not([hidden]) ~ :not([hidden]) {
  border-color: #819cc5;
}

.divideBlue50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eef1f7;
}

.divideBlue700 > :not([hidden]) ~ :not([hidden]) {
  border-color: #375d81;
}

.divideBlue800 > :not([hidden]) ~ :not([hidden]) {
  border-color: #204664;
}

.divideBlue900 > :not([hidden]) ~ :not([hidden]) {
  border-color: #1b3b59;
}

.divideBlue950 > :not([hidden]) ~ :not([hidden]) {
  border-color: #193148;
}

.divideBuyerprimary > :not([hidden]) ~ :not([hidden]) {
  border-color: #c2a661;
}

.divideBuyerprimaryiphone > :not([hidden]) ~ :not([hidden]) {
  border-color: #cca738;
}

.divideBuyersecondary > :not([hidden]) ~ :not([hidden]) {
  border-color: #a48e45;
}

.divideBuyertertiary > :not([hidden]) ~ :not([hidden]) {
  border-color: #f6f3eb;
}

.divideBuyertertiaryalt > :not([hidden]) ~ :not([hidden]) {
  border-color: #e8dfcd;
}

.divideCharcoal > :not([hidden]) ~ :not([hidden]) {
  border-color: #444;
}

.divideCyan200 > :not([hidden]) ~ :not([hidden]) {
  border-color: #c6e6f1;
}

.divideCyan50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #e8f4f9;
}

.divideCyan600 > :not([hidden]) ~ :not([hidden]) {
  border-color: #2683a3;
}

.divideDealerprimary > :not([hidden]) ~ :not([hidden]) {
  border-color: #436b93;
}

.divideDealersecondary > :not([hidden]) ~ :not([hidden]) {
  border-color: #204664;
}

.divideDealertertiary > :not([hidden]) ~ :not([hidden]) {
  border-color: #dee3eb;
}

.divideDealertertiaryalt > :not([hidden]) ~ :not([hidden]) {
  border-color: #d8ecf5;
}

.divideDolphin > :not([hidden]) ~ :not([hidden]) {
  border-color: #ddd;
}

.divideFacebookprimary > :not([hidden]) ~ :not([hidden]) {
  border-color: #3b5998;
}

.divideGray500 > :not([hidden]) ~ :not([hidden]) {
  border-color: #888;
}

.divideGray700 > :not([hidden]) ~ :not([hidden]) {
  border-color: #555;
}

.divideGreen100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #e5f4de;
}

.divideGreen200 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ceecc1;
}

.divideGreen50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eff9ea;
}

.divideGreen700 > :not([hidden]) ~ :not([hidden]) {
  border-color: #418f48;
}

.divideGreen900 > :not([hidden]) ~ :not([hidden]) {
  border-color: #1b5633;
}

.divideGreen950 > :not([hidden]) ~ :not([hidden]) {
  border-color: #0e3c1e;
}

.divideInstagramprimary > :not([hidden]) ~ :not([hidden]) {
  border-color: #275a85;
}

.divideInternalprimary > :not([hidden]) ~ :not([hidden]) {
  border-color: #559b5e;
}

.divideInternalsecondary > :not([hidden]) ~ :not([hidden]) {
  border-color: #2a7140;
}

.divideInternaltertiary > :not([hidden]) ~ :not([hidden]) {
  border-color: #b8e7c8;
}

.divideIron > :not([hidden]) ~ :not([hidden]) {
  border-color: #888;
}

.divideLinkedinprimary > :not([hidden]) ~ :not([hidden]) {
  border-color: #007bb6;
}

.divideMarketingsale > :not([hidden]) ~ :not([hidden]) {
  border-color: #950808;
}

.divideMedalbronze > :not([hidden]) ~ :not([hidden]) {
  border-color: #a06931;
}

.divideMedalgold > :not([hidden]) ~ :not([hidden]) {
  border-color: #8c7236;
}

.divideMedalplatinum > :not([hidden]) ~ :not([hidden]) {
  border-color: #727383;
}

.divideMedalsilver > :not([hidden]) ~ :not([hidden]) {
  border-color: #637583;
}

.divideMoonstone > :not([hidden]) ~ :not([hidden]) {
  border-color: #f3f3f3;
}

.divideNftwine > :not([hidden]) ~ :not([hidden]) {
  border-color: #610034;
}

.divideNoir > :not([hidden]) ~ :not([hidden]) {
  border-color: #222;
}

.divideOverlaybackground > :not([hidden]) ~ :not([hidden]) {
  border-color: #000000b3;
}

.divideOverlaylight > :not([hidden]) ~ :not([hidden]) {
  border-color: #fff6;
}

.dividePinterestprimary > :not([hidden]) ~ :not([hidden]) {
  border-color: #e00002;
}

.dividePitchblack > :not([hidden]) ~ :not([hidden]) {
  border-color: #000;
}

.divideRed200 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fecaca;
}

.divideRed50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fef2f2;
}

.divideRed700 > :not([hidden]) ~ :not([hidden]) {
  border-color: #c00;
}

.divideRed900 > :not([hidden]) ~ :not([hidden]) {
  border-color: #770303;
}

.divideSatan > :not([hidden]) ~ :not([hidden]) {
  border-color: #666;
}

.divideShagreen200 > :not([hidden]) ~ :not([hidden]) {
  border-color: #cbdbdb;
}

.divideShagreen300 > :not([hidden]) ~ :not([hidden]) {
  border-color: #bacecc;
}

.divideShagreen400 > :not([hidden]) ~ :not([hidden]) {
  border-color: #aabeb9;
}

.divideShagreen50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #edf3f3;
}

.divideShagreen500 > :not([hidden]) ~ :not([hidden]) {
  border-color: #8fa8aa;
}

.divideShagreen600 > :not([hidden]) ~ :not([hidden]) {
  border-color: #73919b;
}

.divideShagreen700 > :not([hidden]) ~ :not([hidden]) {
  border-color: #517884;
}

.divideShagreen800 > :not([hidden]) ~ :not([hidden]) {
  border-color: #2e5f6c;
}

.divideShagreen950 > :not([hidden]) ~ :not([hidden]) {
  border-color: #223f4c;
}

.divideStorm > :not([hidden]) ~ :not([hidden]) {
  border-color: #bbb;
}

.divideSuccessprimary > :not([hidden]) ~ :not([hidden]) {
  border-color: #66a559;
}

.divideSuccesssecondary > :not([hidden]) ~ :not([hidden]) {
  border-color: #2a7140;
}

.divideSuccesstertiary > :not([hidden]) ~ :not([hidden]) {
  border-color: #aedfa3;
}

.divideTangerine500 > :not([hidden]) ~ :not([hidden]) {
  border-color: #f9902d;
}

.divideTangerine700 > :not([hidden]) ~ :not([hidden]) {
  border-color: #d26a00;
}

.divideTrademember > :not([hidden]) ~ :not([hidden]) {
  border-color: #666;
}

.divideTradeprimary > :not([hidden]) ~ :not([hidden]) {
  border-color: #0f8d88;
}

.divideTradesecondary > :not([hidden]) ~ :not([hidden]) {
  border-color: #0d7d77;
}

.divideTradetertiary > :not([hidden]) ~ :not([hidden]) {
  border-color: #e7f4f3;
}

.divideWarningprimary > :not([hidden]) ~ :not([hidden]) {
  border-color: #f07f04;
}

.divideWarningsecondary > :not([hidden]) ~ :not([hidden]) {
  border-color: #ffc100;
}

.divideWhite > :not([hidden]) ~ :not([hidden]) {
  border-color: #fff;
}

.divideYellow100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ffe9b6;
}

.divideYellow200 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ffdf8b;
}

.divideYellow300 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ffd660;
}

.divideYellow50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #fff4e0;
}

.divideYellow700 > :not([hidden]) ~ :not([hidden]) {
  border-color: #be9116;
}

.divideYellow950 > :not([hidden]) ~ :not([hidden]) {
  border-color: #5d4a20;
}

.divideCurrent > :not([hidden]) ~ :not([hidden]) {
  border-color: currentColor;
}

.divideTransparent > :not([hidden]) ~ :not([hidden]) {
  border-color: #0000;
}

.placeSelfAuto {
  place-self: auto;
}

.placeSelfStart {
  place-self: start;
}

.placeSelfEnd {
  place-self: end;
}

.placeSelfCenter {
  place-self: center;
}

.placeSelfStretch {
  place-self: stretch stretch;
}

.selfAuto {
  align-self: auto;
}

.selfStart {
  align-self: flex-start;
}

.selfEnd {
  align-self: flex-end;
}

.selfCenter {
  align-self: center;
}

.selfStretch {
  align-self: stretch;
}

.selfBaseline {
  align-self: baseline;
}

.justifySelfAuto {
  justify-self: auto;
}

.justifySelfStart {
  justify-self: start;
}

.justifySelfEnd {
  justify-self: end;
}

.justifySelfCenter {
  justify-self: center;
}

.justifySelfStretch {
  justify-self: stretch;
}

.overflowAuto {
  overflow: auto;
}

.overflowHidden {
  overflow: hidden;
}

.overflowClip {
  overflow: clip;
}

.overflowVisible {
  overflow: visible;
}

.overflowScroll {
  overflow: scroll;
}

.overflowXAuto {
  overflow-x: auto;
}

.overflowYAuto {
  overflow-y: auto;
}

.overflowXHidden {
  overflow-x: hidden;
}

.overflowYHidden {
  overflow-y: hidden;
}

.overflowXClip {
  overflow-x: clip;
}

.overflowYClip {
  overflow-y: clip;
}

.overflowXVisible {
  overflow-x: visible;
}

.overflowYVisible {
  overflow-y: visible;
}

.overflowXScroll {
  overflow-x: scroll;
}

.overflowYScroll {
  overflow-y: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.overflowEllipsis, .textEllipsis {
  text-overflow: ellipsis;
}

.textClip {
  text-overflow: clip;
}

.hyphensNone {
  hyphens: none;
}

.hyphensManual {
  hyphens: manual;
}

.hyphensAuto {
  hyphens: auto;
}

.whitespaceNormal {
  white-space: normal;
}

.whitespaceNowrap {
  white-space: nowrap;
}

.whitespacePre {
  white-space: pre;
}

.whitespacePreLine {
  white-space: pre-line;
}

.whitespacePreWrap {
  white-space: pre-wrap;
}

.whitespaceBreakSpaces {
  white-space: break-spaces;
}

.breakNormal {
  overflow-wrap: normal;
  word-break: normal;
}

.breakWords {
  overflow-wrap: break-word;
}

.breakAll {
  word-break: break-all;
}

.breakKeep {
  word-break: keep-all;
}

.rounded0 {
  border-radius: 0;
}

.rounded1px {
  border-radius: 1px;
}

.rounded2px {
  border-radius: 2px;
}

.rounded3px {
  border-radius: 3px;
}

.roundedLarge {
  border-radius: 36px;
}

.roundedLarger {
  border-radius: 45px;
}

.roundedMedium {
  border-radius: 27px;
}

.roundedSmall {
  border-radius: 18px;
}

.roundedSmaller {
  border-radius: 14px;
}

.roundedXlarge {
  border-radius: 54px;
}

.roundedXsmall {
  border-radius: 9px;
}

.roundedXxlarge {
  border-radius: 72px;
}

.roundedXxsmall {
  border-radius: 5px;
}

.roundedFull {
  border-radius: 9999px;
}

.roundedNone {
  border-radius: 0;
}

.roundedB0 {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.roundedB1px {
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
}

.roundedB2px {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.roundedB3px {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.roundedBLarge {
  border-bottom-right-radius: 36px;
  border-bottom-left-radius: 36px;
}

.roundedBLarger {
  border-bottom-right-radius: 45px;
  border-bottom-left-radius: 45px;
}

.roundedBMedium {
  border-bottom-right-radius: 27px;
  border-bottom-left-radius: 27px;
}

.roundedBSmall {
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 18px;
}

.roundedBSmaller {
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
}

.roundedBXlarge {
  border-bottom-right-radius: 54px;
  border-bottom-left-radius: 54px;
}

.roundedBXsmall {
  border-bottom-right-radius: 9px;
  border-bottom-left-radius: 9px;
}

.roundedBXxlarge {
  border-bottom-right-radius: 72px;
  border-bottom-left-radius: 72px;
}

.roundedBXxsmall {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.roundedBFull {
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.roundedBNone {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.roundedE0 {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.roundedE1px {
  border-start-end-radius: 1px;
  border-end-end-radius: 1px;
}

.roundedE2px {
  border-start-end-radius: 2px;
  border-end-end-radius: 2px;
}

.roundedE3px {
  border-start-end-radius: 3px;
  border-end-end-radius: 3px;
}

.roundedELarge {
  border-start-end-radius: 36px;
  border-end-end-radius: 36px;
}

.roundedELarger {
  border-start-end-radius: 45px;
  border-end-end-radius: 45px;
}

.roundedEMedium {
  border-start-end-radius: 27px;
  border-end-end-radius: 27px;
}

.roundedESmall {
  border-start-end-radius: 18px;
  border-end-end-radius: 18px;
}

.roundedESmaller {
  border-start-end-radius: 14px;
  border-end-end-radius: 14px;
}

.roundedEXlarge {
  border-start-end-radius: 54px;
  border-end-end-radius: 54px;
}

.roundedEXsmall {
  border-start-end-radius: 9px;
  border-end-end-radius: 9px;
}

.roundedEXxlarge {
  border-start-end-radius: 72px;
  border-end-end-radius: 72px;
}

.roundedEXxsmall {
  border-start-end-radius: 5px;
  border-end-end-radius: 5px;
}

.roundedEFull {
  border-start-end-radius: 9999px;
  border-end-end-radius: 9999px;
}

.roundedENone {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.roundedL0 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.roundedL1px {
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
}

.roundedL2px {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.roundedL3px {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.roundedLLarge {
  border-top-left-radius: 36px;
  border-bottom-left-radius: 36px;
}

.roundedLLarger {
  border-top-left-radius: 45px;
  border-bottom-left-radius: 45px;
}

.roundedLMedium {
  border-top-left-radius: 27px;
  border-bottom-left-radius: 27px;
}

.roundedLSmall {
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
}

.roundedLSmaller {
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
}

.roundedLXlarge {
  border-top-left-radius: 54px;
  border-bottom-left-radius: 54px;
}

.roundedLXsmall {
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}

.roundedLXxlarge {
  border-top-left-radius: 72px;
  border-bottom-left-radius: 72px;
}

.roundedLXxsmall {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.roundedLFull {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.roundedLNone {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.roundedR0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.roundedR1px {
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
}

.roundedR2px {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.roundedR3px {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.roundedRLarge {
  border-top-right-radius: 36px;
  border-bottom-right-radius: 36px;
}

.roundedRLarger {
  border-top-right-radius: 45px;
  border-bottom-right-radius: 45px;
}

.roundedRMedium {
  border-top-right-radius: 27px;
  border-bottom-right-radius: 27px;
}

.roundedRSmall {
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
}

.roundedRSmaller {
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}

.roundedRXlarge {
  border-top-right-radius: 54px;
  border-bottom-right-radius: 54px;
}

.roundedRXsmall {
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}

.roundedRXxlarge {
  border-top-right-radius: 72px;
  border-bottom-right-radius: 72px;
}

.roundedRXxsmall {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.roundedRFull {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.roundedRNone {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.roundedS0 {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.roundedS1px {
  border-start-start-radius: 1px;
  border-end-start-radius: 1px;
}

.roundedS2px {
  border-start-start-radius: 2px;
  border-end-start-radius: 2px;
}

.roundedS3px {
  border-start-start-radius: 3px;
  border-end-start-radius: 3px;
}

.roundedSLarge {
  border-start-start-radius: 36px;
  border-end-start-radius: 36px;
}

.roundedSLarger {
  border-start-start-radius: 45px;
  border-end-start-radius: 45px;
}

.roundedSMedium {
  border-start-start-radius: 27px;
  border-end-start-radius: 27px;
}

.roundedSSmall {
  border-start-start-radius: 18px;
  border-end-start-radius: 18px;
}

.roundedSSmaller {
  border-start-start-radius: 14px;
  border-end-start-radius: 14px;
}

.roundedSXlarge {
  border-start-start-radius: 54px;
  border-end-start-radius: 54px;
}

.roundedSXsmall {
  border-start-start-radius: 9px;
  border-end-start-radius: 9px;
}

.roundedSXxlarge {
  border-start-start-radius: 72px;
  border-end-start-radius: 72px;
}

.roundedSXxsmall {
  border-start-start-radius: 5px;
  border-end-start-radius: 5px;
}

.roundedSFull {
  border-start-start-radius: 9999px;
  border-end-start-radius: 9999px;
}

.roundedSNone {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.roundedT0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.roundedT1px {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}

.roundedT2px {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.roundedT3px {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.roundedTLarge {
  border-top-left-radius: 36px;
  border-top-right-radius: 36px;
}

.roundedTLarger {
  border-top-left-radius: 45px;
  border-top-right-radius: 45px;
}

.roundedTMedium {
  border-top-left-radius: 27px;
  border-top-right-radius: 27px;
}

.roundedTSmall {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

.roundedTSmaller {
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}

.roundedTXlarge {
  border-top-left-radius: 54px;
  border-top-right-radius: 54px;
}

.roundedTXsmall {
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.roundedTXxlarge {
  border-top-left-radius: 72px;
  border-top-right-radius: 72px;
}

.roundedTXxsmall {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.roundedTFull {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}

.roundedTNone {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.roundedBl0 {
  border-bottom-left-radius: 0;
}

.roundedBl1px {
  border-bottom-left-radius: 1px;
}

.roundedBl2px {
  border-bottom-left-radius: 2px;
}

.roundedBl3px {
  border-bottom-left-radius: 3px;
}

.roundedBlLarge {
  border-bottom-left-radius: 36px;
}

.roundedBlLarger {
  border-bottom-left-radius: 45px;
}

.roundedBlMedium {
  border-bottom-left-radius: 27px;
}

.roundedBlSmall {
  border-bottom-left-radius: 18px;
}

.roundedBlSmaller {
  border-bottom-left-radius: 14px;
}

.roundedBlXlarge {
  border-bottom-left-radius: 54px;
}

.roundedBlXsmall {
  border-bottom-left-radius: 9px;
}

.roundedBlXxlarge {
  border-bottom-left-radius: 72px;
}

.roundedBlXxsmall {
  border-bottom-left-radius: 5px;
}

.roundedBlFull {
  border-bottom-left-radius: 9999px;
}

.roundedBlNone {
  border-bottom-left-radius: 0;
}

.roundedBr0 {
  border-bottom-right-radius: 0;
}

.roundedBr1px {
  border-bottom-right-radius: 1px;
}

.roundedBr2px {
  border-bottom-right-radius: 2px;
}

.roundedBr3px {
  border-bottom-right-radius: 3px;
}

.roundedBrLarge {
  border-bottom-right-radius: 36px;
}

.roundedBrLarger {
  border-bottom-right-radius: 45px;
}

.roundedBrMedium {
  border-bottom-right-radius: 27px;
}

.roundedBrSmall {
  border-bottom-right-radius: 18px;
}

.roundedBrSmaller {
  border-bottom-right-radius: 14px;
}

.roundedBrXlarge {
  border-bottom-right-radius: 54px;
}

.roundedBrXsmall {
  border-bottom-right-radius: 9px;
}

.roundedBrXxlarge {
  border-bottom-right-radius: 72px;
}

.roundedBrXxsmall {
  border-bottom-right-radius: 5px;
}

.roundedBrFull {
  border-bottom-right-radius: 9999px;
}

.roundedBrNone {
  border-bottom-right-radius: 0;
}

.roundedEe0 {
  border-end-end-radius: 0;
}

.roundedEe1px {
  border-end-end-radius: 1px;
}

.roundedEe2px {
  border-end-end-radius: 2px;
}

.roundedEe3px {
  border-end-end-radius: 3px;
}

.roundedEeLarge {
  border-end-end-radius: 36px;
}

.roundedEeLarger {
  border-end-end-radius: 45px;
}

.roundedEeMedium {
  border-end-end-radius: 27px;
}

.roundedEeSmall {
  border-end-end-radius: 18px;
}

.roundedEeSmaller {
  border-end-end-radius: 14px;
}

.roundedEeXlarge {
  border-end-end-radius: 54px;
}

.roundedEeXsmall {
  border-end-end-radius: 9px;
}

.roundedEeXxlarge {
  border-end-end-radius: 72px;
}

.roundedEeXxsmall {
  border-end-end-radius: 5px;
}

.roundedEeFull {
  border-end-end-radius: 9999px;
}

.roundedEeNone {
  border-end-end-radius: 0;
}

.roundedEs0 {
  border-end-start-radius: 0;
}

.roundedEs1px {
  border-end-start-radius: 1px;
}

.roundedEs2px {
  border-end-start-radius: 2px;
}

.roundedEs3px {
  border-end-start-radius: 3px;
}

.roundedEsLarge {
  border-end-start-radius: 36px;
}

.roundedEsLarger {
  border-end-start-radius: 45px;
}

.roundedEsMedium {
  border-end-start-radius: 27px;
}

.roundedEsSmall {
  border-end-start-radius: 18px;
}

.roundedEsSmaller {
  border-end-start-radius: 14px;
}

.roundedEsXlarge {
  border-end-start-radius: 54px;
}

.roundedEsXsmall {
  border-end-start-radius: 9px;
}

.roundedEsXxlarge {
  border-end-start-radius: 72px;
}

.roundedEsXxsmall {
  border-end-start-radius: 5px;
}

.roundedEsFull {
  border-end-start-radius: 9999px;
}

.roundedEsNone {
  border-end-start-radius: 0;
}

.roundedSe0 {
  border-start-end-radius: 0;
}

.roundedSe1px {
  border-start-end-radius: 1px;
}

.roundedSe2px {
  border-start-end-radius: 2px;
}

.roundedSe3px {
  border-start-end-radius: 3px;
}

.roundedSeLarge {
  border-start-end-radius: 36px;
}

.roundedSeLarger {
  border-start-end-radius: 45px;
}

.roundedSeMedium {
  border-start-end-radius: 27px;
}

.roundedSeSmall {
  border-start-end-radius: 18px;
}

.roundedSeSmaller {
  border-start-end-radius: 14px;
}

.roundedSeXlarge {
  border-start-end-radius: 54px;
}

.roundedSeXsmall {
  border-start-end-radius: 9px;
}

.roundedSeXxlarge {
  border-start-end-radius: 72px;
}

.roundedSeXxsmall {
  border-start-end-radius: 5px;
}

.roundedSeFull {
  border-start-end-radius: 9999px;
}

.roundedSeNone {
  border-start-end-radius: 0;
}

.roundedSs0 {
  border-start-start-radius: 0;
}

.roundedSs1px {
  border-start-start-radius: 1px;
}

.roundedSs2px {
  border-start-start-radius: 2px;
}

.roundedSs3px {
  border-start-start-radius: 3px;
}

.roundedSsLarge {
  border-start-start-radius: 36px;
}

.roundedSsLarger {
  border-start-start-radius: 45px;
}

.roundedSsMedium {
  border-start-start-radius: 27px;
}

.roundedSsSmall {
  border-start-start-radius: 18px;
}

.roundedSsSmaller {
  border-start-start-radius: 14px;
}

.roundedSsXlarge {
  border-start-start-radius: 54px;
}

.roundedSsXsmall {
  border-start-start-radius: 9px;
}

.roundedSsXxlarge {
  border-start-start-radius: 72px;
}

.roundedSsXxsmall {
  border-start-start-radius: 5px;
}

.roundedSsFull {
  border-start-start-radius: 9999px;
}

.roundedSsNone {
  border-start-start-radius: 0;
}

.roundedTl0 {
  border-top-left-radius: 0;
}

.roundedTl1px {
  border-top-left-radius: 1px;
}

.roundedTl2px {
  border-top-left-radius: 2px;
}

.roundedTl3px {
  border-top-left-radius: 3px;
}

.roundedTlLarge {
  border-top-left-radius: 36px;
}

.roundedTlLarger {
  border-top-left-radius: 45px;
}

.roundedTlMedium {
  border-top-left-radius: 27px;
}

.roundedTlSmall {
  border-top-left-radius: 18px;
}

.roundedTlSmaller {
  border-top-left-radius: 14px;
}

.roundedTlXlarge {
  border-top-left-radius: 54px;
}

.roundedTlXsmall {
  border-top-left-radius: 9px;
}

.roundedTlXxlarge {
  border-top-left-radius: 72px;
}

.roundedTlXxsmall {
  border-top-left-radius: 5px;
}

.roundedTlFull {
  border-top-left-radius: 9999px;
}

.roundedTlNone {
  border-top-left-radius: 0;
}

.roundedTr0 {
  border-top-right-radius: 0;
}

.roundedTr1px {
  border-top-right-radius: 1px;
}

.roundedTr2px {
  border-top-right-radius: 2px;
}

.roundedTr3px {
  border-top-right-radius: 3px;
}

.roundedTrLarge {
  border-top-right-radius: 36px;
}

.roundedTrLarger {
  border-top-right-radius: 45px;
}

.roundedTrMedium {
  border-top-right-radius: 27px;
}

.roundedTrSmall {
  border-top-right-radius: 18px;
}

.roundedTrSmaller {
  border-top-right-radius: 14px;
}

.roundedTrXlarge {
  border-top-right-radius: 54px;
}

.roundedTrXsmall {
  border-top-right-radius: 9px;
}

.roundedTrXxlarge {
  border-top-right-radius: 72px;
}

.roundedTrXxsmall {
  border-top-right-radius: 5px;
}

.roundedTrFull {
  border-top-right-radius: 9999px;
}

.roundedTrNone {
  border-top-right-radius: 0;
}

.border {
  border-width: 1px;
}

.border0 {
  border-width: 0;
}

.border2 {
  border-width: 2px;
}

.border4 {
  border-width: 4px;
}

.border8 {
  border-width: 8px;
}

.borderX {
  border-left-width: 1px;
  border-right-width: 1px;
}

.borderX0 {
  border-left-width: 0;
  border-right-width: 0;
}

.borderX2 {
  border-left-width: 2px;
  border-right-width: 2px;
}

.borderX4 {
  border-left-width: 4px;
  border-right-width: 4px;
}

.borderX8 {
  border-left-width: 8px;
  border-right-width: 8px;
}

.borderY {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.borderY0 {
  border-top-width: 0;
  border-bottom-width: 0;
}

.borderY2 {
  border-top-width: 2px;
  border-bottom-width: 2px;
}

.borderY4 {
  border-top-width: 4px;
  border-bottom-width: 4px;
}

.borderY8 {
  border-top-width: 8px;
  border-bottom-width: 8px;
}

.borderB {
  border-bottom-width: 1px;
}

.borderB0 {
  border-bottom-width: 0;
}

.borderB2 {
  border-bottom-width: 2px;
}

.borderB4 {
  border-bottom-width: 4px;
}

.borderB8 {
  border-bottom-width: 8px;
}

.borderE {
  border-inline-end-width: 1px;
}

.borderE0 {
  border-inline-end-width: 0;
}

.borderE2 {
  border-inline-end-width: 2px;
}

.borderE4 {
  border-inline-end-width: 4px;
}

.borderE8 {
  border-inline-end-width: 8px;
}

.borderL {
  border-left-width: 1px;
}

.borderL0 {
  border-left-width: 0;
}

.borderL2 {
  border-left-width: 2px;
}

.borderL4 {
  border-left-width: 4px;
}

.borderL8 {
  border-left-width: 8px;
}

.borderR {
  border-right-width: 1px;
}

.borderR0 {
  border-right-width: 0;
}

.borderR2 {
  border-right-width: 2px;
}

.borderR4 {
  border-right-width: 4px;
}

.borderR8 {
  border-right-width: 8px;
}

.borderS {
  border-inline-start-width: 1px;
}

.borderS0 {
  border-inline-start-width: 0;
}

.borderS2 {
  border-inline-start-width: 2px;
}

.borderS4 {
  border-inline-start-width: 4px;
}

.borderS8 {
  border-inline-start-width: 8px;
}

.borderT {
  border-top-width: 1px;
}

.borderT0 {
  border-top-width: 0;
}

.borderT2 {
  border-top-width: 2px;
}

.borderT4 {
  border-top-width: 4px;
}

.borderT8 {
  border-top-width: 8px;
}

.borderSolid {
  border-style: solid;
}

.borderDashed {
  border-style: dashed;
}

.borderDotted {
  border-style: dotted;
}

.borderDouble {
  border-style: double;
}

.borderHidden {
  border-style: hidden;
}

.borderNone {
  border-style: none;
}

.borderAlabaster {
  border-color: #fbfbfb;
}

.borderAlertprimary {
  border-color: #c00;
}

.borderAlertsecondary {
  border-color: #950808;
}

.borderAlerttertiary {
  border-color: #fedcde;
}

.borderBlue400 {
  border-color: #819cc5;
}

.borderBlue50 {
  border-color: #eef1f7;
}

.borderBlue700 {
  border-color: #375d81;
}

.borderBlue800 {
  border-color: #204664;
}

.borderBlue900 {
  border-color: #1b3b59;
}

.borderBlue950 {
  border-color: #193148;
}

.borderBuyerprimary {
  border-color: #c2a661;
}

.borderBuyerprimaryiphone {
  border-color: #cca738;
}

.borderBuyersecondary {
  border-color: #a48e45;
}

.borderBuyertertiary {
  border-color: #f6f3eb;
}

.borderBuyertertiaryalt {
  border-color: #e8dfcd;
}

.borderCharcoal {
  border-color: #444;
}

.borderCyan200 {
  border-color: #c6e6f1;
}

.borderCyan50 {
  border-color: #e8f4f9;
}

.borderCyan600 {
  border-color: #2683a3;
}

.borderDealerprimary {
  border-color: #436b93;
}

.borderDealersecondary {
  border-color: #204664;
}

.borderDealertertiary {
  border-color: #dee3eb;
}

.borderDealertertiaryalt {
  border-color: #d8ecf5;
}

.borderDolphin {
  border-color: #ddd;
}

.borderFacebookprimary {
  border-color: #3b5998;
}

.borderGray500 {
  border-color: #888;
}

.borderGray700 {
  border-color: #555;
}

.borderGreen100 {
  border-color: #e5f4de;
}

.borderGreen200 {
  border-color: #ceecc1;
}

.borderGreen50 {
  border-color: #eff9ea;
}

.borderGreen700 {
  border-color: #418f48;
}

.borderGreen900 {
  border-color: #1b5633;
}

.borderGreen950 {
  border-color: #0e3c1e;
}

.borderInstagramprimary {
  border-color: #275a85;
}

.borderInternalprimary {
  border-color: #559b5e;
}

.borderInternalsecondary {
  border-color: #2a7140;
}

.borderInternaltertiary {
  border-color: #b8e7c8;
}

.borderIron {
  border-color: #888;
}

.borderLinkedinprimary {
  border-color: #007bb6;
}

.borderMarketingsale {
  border-color: #950808;
}

.borderMedalbronze {
  border-color: #a06931;
}

.borderMedalgold {
  border-color: #8c7236;
}

.borderMedalplatinum {
  border-color: #727383;
}

.borderMedalsilver {
  border-color: #637583;
}

.borderMoonstone {
  border-color: #f3f3f3;
}

.borderNftwine {
  border-color: #610034;
}

.borderNoir {
  border-color: #222;
}

.borderOverlaybackground {
  border-color: #000000b3;
}

.borderOverlaylight {
  border-color: #fff6;
}

.borderPinterestprimary {
  border-color: #e00002;
}

.borderPitchblack {
  border-color: #000;
}

.borderRed200 {
  border-color: #fecaca;
}

.borderRed50 {
  border-color: #fef2f2;
}

.borderRed700 {
  border-color: #c00;
}

.borderRed900 {
  border-color: #770303;
}

.borderSatan {
  border-color: #666;
}

.borderShagreen200 {
  border-color: #cbdbdb;
}

.borderShagreen300 {
  border-color: #bacecc;
}

.borderShagreen400 {
  border-color: #aabeb9;
}

.borderShagreen50 {
  border-color: #edf3f3;
}

.borderShagreen500 {
  border-color: #8fa8aa;
}

.borderShagreen600 {
  border-color: #73919b;
}

.borderShagreen700 {
  border-color: #517884;
}

.borderShagreen800 {
  border-color: #2e5f6c;
}

.borderShagreen950 {
  border-color: #223f4c;
}

.borderStorm {
  border-color: #bbb;
}

.borderSuccessprimary {
  border-color: #66a559;
}

.borderSuccesssecondary {
  border-color: #2a7140;
}

.borderSuccesstertiary {
  border-color: #aedfa3;
}

.borderTangerine500 {
  border-color: #f9902d;
}

.borderTangerine700 {
  border-color: #d26a00;
}

.borderTrademember {
  border-color: #666;
}

.borderTradeprimary {
  border-color: #0f8d88;
}

.borderTradesecondary {
  border-color: #0d7d77;
}

.borderTradetertiary {
  border-color: #e7f4f3;
}

.borderWarningprimary {
  border-color: #f07f04;
}

.borderWarningsecondary {
  border-color: #ffc100;
}

.borderWhite {
  border-color: #fff;
}

.borderYellow100 {
  border-color: #ffe9b6;
}

.borderYellow200 {
  border-color: #ffdf8b;
}

.borderYellow300 {
  border-color: #ffd660;
}

.borderYellow50 {
  border-color: #fff4e0;
}

.borderYellow700 {
  border-color: #be9116;
}

.borderYellow950 {
  border-color: #5d4a20;
}

.borderCurrent {
  border-color: currentColor;
}

.borderTransparent {
  border-color: #0000;
}

.borderXAlabaster {
  border-left-color: #fbfbfb;
  border-right-color: #fbfbfb;
}

.borderXAlertprimary {
  border-left-color: #c00;
  border-right-color: #c00;
}

.borderXAlertsecondary {
  border-left-color: #950808;
  border-right-color: #950808;
}

.borderXAlerttertiary {
  border-left-color: #fedcde;
  border-right-color: #fedcde;
}

.borderXBlue400 {
  border-left-color: #819cc5;
  border-right-color: #819cc5;
}

.borderXBlue50 {
  border-left-color: #eef1f7;
  border-right-color: #eef1f7;
}

.borderXBlue700 {
  border-left-color: #375d81;
  border-right-color: #375d81;
}

.borderXBlue800 {
  border-left-color: #204664;
  border-right-color: #204664;
}

.borderXBlue900 {
  border-left-color: #1b3b59;
  border-right-color: #1b3b59;
}

.borderXBlue950 {
  border-left-color: #193148;
  border-right-color: #193148;
}

.borderXBuyerprimary {
  border-left-color: #c2a661;
  border-right-color: #c2a661;
}

.borderXBuyerprimaryiphone {
  border-left-color: #cca738;
  border-right-color: #cca738;
}

.borderXBuyersecondary {
  border-left-color: #a48e45;
  border-right-color: #a48e45;
}

.borderXBuyertertiary {
  border-left-color: #f6f3eb;
  border-right-color: #f6f3eb;
}

.borderXBuyertertiaryalt {
  border-left-color: #e8dfcd;
  border-right-color: #e8dfcd;
}

.borderXCharcoal {
  border-left-color: #444;
  border-right-color: #444;
}

.borderXCyan200 {
  border-left-color: #c6e6f1;
  border-right-color: #c6e6f1;
}

.borderXCyan50 {
  border-left-color: #e8f4f9;
  border-right-color: #e8f4f9;
}

.borderXCyan600 {
  border-left-color: #2683a3;
  border-right-color: #2683a3;
}

.borderXDealerprimary {
  border-left-color: #436b93;
  border-right-color: #436b93;
}

.borderXDealersecondary {
  border-left-color: #204664;
  border-right-color: #204664;
}

.borderXDealertertiary {
  border-left-color: #dee3eb;
  border-right-color: #dee3eb;
}

.borderXDealertertiaryalt {
  border-left-color: #d8ecf5;
  border-right-color: #d8ecf5;
}

.borderXDolphin {
  border-left-color: #ddd;
  border-right-color: #ddd;
}

.borderXFacebookprimary {
  border-left-color: #3b5998;
  border-right-color: #3b5998;
}

.borderXGray500 {
  border-left-color: #888;
  border-right-color: #888;
}

.borderXGray700 {
  border-left-color: #555;
  border-right-color: #555;
}

.borderXGreen100 {
  border-left-color: #e5f4de;
  border-right-color: #e5f4de;
}

.borderXGreen200 {
  border-left-color: #ceecc1;
  border-right-color: #ceecc1;
}

.borderXGreen50 {
  border-left-color: #eff9ea;
  border-right-color: #eff9ea;
}

.borderXGreen700 {
  border-left-color: #418f48;
  border-right-color: #418f48;
}

.borderXGreen900 {
  border-left-color: #1b5633;
  border-right-color: #1b5633;
}

.borderXGreen950 {
  border-left-color: #0e3c1e;
  border-right-color: #0e3c1e;
}

.borderXInstagramprimary {
  border-left-color: #275a85;
  border-right-color: #275a85;
}

.borderXInternalprimary {
  border-left-color: #559b5e;
  border-right-color: #559b5e;
}

.borderXInternalsecondary {
  border-left-color: #2a7140;
  border-right-color: #2a7140;
}

.borderXInternaltertiary {
  border-left-color: #b8e7c8;
  border-right-color: #b8e7c8;
}

.borderXIron {
  border-left-color: #888;
  border-right-color: #888;
}

.borderXLinkedinprimary {
  border-left-color: #007bb6;
  border-right-color: #007bb6;
}

.borderXMarketingsale {
  border-left-color: #950808;
  border-right-color: #950808;
}

.borderXMedalbronze {
  border-left-color: #a06931;
  border-right-color: #a06931;
}

.borderXMedalgold {
  border-left-color: #8c7236;
  border-right-color: #8c7236;
}

.borderXMedalplatinum {
  border-left-color: #727383;
  border-right-color: #727383;
}

.borderXMedalsilver {
  border-left-color: #637583;
  border-right-color: #637583;
}

.borderXMoonstone {
  border-left-color: #f3f3f3;
  border-right-color: #f3f3f3;
}

.borderXNftwine {
  border-left-color: #610034;
  border-right-color: #610034;
}

.borderXNoir {
  border-left-color: #222;
  border-right-color: #222;
}

.borderXOverlaybackground {
  border-left-color: #000000b3;
  border-right-color: #000000b3;
}

.borderXOverlaylight {
  border-left-color: #fff6;
  border-right-color: #fff6;
}

.borderXPinterestprimary {
  border-left-color: #e00002;
  border-right-color: #e00002;
}

.borderXPitchblack {
  border-left-color: #000;
  border-right-color: #000;
}

.borderXRed200 {
  border-left-color: #fecaca;
  border-right-color: #fecaca;
}

.borderXRed50 {
  border-left-color: #fef2f2;
  border-right-color: #fef2f2;
}

.borderXRed700 {
  border-left-color: #c00;
  border-right-color: #c00;
}

.borderXRed900 {
  border-left-color: #770303;
  border-right-color: #770303;
}

.borderXSatan {
  border-left-color: #666;
  border-right-color: #666;
}

.borderXShagreen200 {
  border-left-color: #cbdbdb;
  border-right-color: #cbdbdb;
}

.borderXShagreen300 {
  border-left-color: #bacecc;
  border-right-color: #bacecc;
}

.borderXShagreen400 {
  border-left-color: #aabeb9;
  border-right-color: #aabeb9;
}

.borderXShagreen50 {
  border-left-color: #edf3f3;
  border-right-color: #edf3f3;
}

.borderXShagreen500 {
  border-left-color: #8fa8aa;
  border-right-color: #8fa8aa;
}

.borderXShagreen600 {
  border-left-color: #73919b;
  border-right-color: #73919b;
}

.borderXShagreen700 {
  border-left-color: #517884;
  border-right-color: #517884;
}

.borderXShagreen800 {
  border-left-color: #2e5f6c;
  border-right-color: #2e5f6c;
}

.borderXShagreen950 {
  border-left-color: #223f4c;
  border-right-color: #223f4c;
}

.borderXStorm {
  border-left-color: #bbb;
  border-right-color: #bbb;
}

.borderXSuccessprimary {
  border-left-color: #66a559;
  border-right-color: #66a559;
}

.borderXSuccesssecondary {
  border-left-color: #2a7140;
  border-right-color: #2a7140;
}

.borderXSuccesstertiary {
  border-left-color: #aedfa3;
  border-right-color: #aedfa3;
}

.borderXTangerine500 {
  border-left-color: #f9902d;
  border-right-color: #f9902d;
}

.borderXTangerine700 {
  border-left-color: #d26a00;
  border-right-color: #d26a00;
}

.borderXTrademember {
  border-left-color: #666;
  border-right-color: #666;
}

.borderXTradeprimary {
  border-left-color: #0f8d88;
  border-right-color: #0f8d88;
}

.borderXTradesecondary {
  border-left-color: #0d7d77;
  border-right-color: #0d7d77;
}

.borderXTradetertiary {
  border-left-color: #e7f4f3;
  border-right-color: #e7f4f3;
}

.borderXWarningprimary {
  border-left-color: #f07f04;
  border-right-color: #f07f04;
}

.borderXWarningsecondary {
  border-left-color: #ffc100;
  border-right-color: #ffc100;
}

.borderXWhite {
  border-left-color: #fff;
  border-right-color: #fff;
}

.borderXYellow100 {
  border-left-color: #ffe9b6;
  border-right-color: #ffe9b6;
}

.borderXYellow200 {
  border-left-color: #ffdf8b;
  border-right-color: #ffdf8b;
}

.borderXYellow300 {
  border-left-color: #ffd660;
  border-right-color: #ffd660;
}

.borderXYellow50 {
  border-left-color: #fff4e0;
  border-right-color: #fff4e0;
}

.borderXYellow700 {
  border-left-color: #be9116;
  border-right-color: #be9116;
}

.borderXYellow950 {
  border-left-color: #5d4a20;
  border-right-color: #5d4a20;
}

.borderXCurrent {
  border-left-color: currentColor;
  border-right-color: currentColor;
}

.borderXTransparent {
  border-left-color: #0000;
  border-right-color: #0000;
}

.borderYAlabaster {
  border-top-color: #fbfbfb;
  border-bottom-color: #fbfbfb;
}

.borderYAlertprimary {
  border-top-color: #c00;
  border-bottom-color: #c00;
}

.borderYAlertsecondary {
  border-top-color: #950808;
  border-bottom-color: #950808;
}

.borderYAlerttertiary {
  border-top-color: #fedcde;
  border-bottom-color: #fedcde;
}

.borderYBlue400 {
  border-top-color: #819cc5;
  border-bottom-color: #819cc5;
}

.borderYBlue50 {
  border-top-color: #eef1f7;
  border-bottom-color: #eef1f7;
}

.borderYBlue700 {
  border-top-color: #375d81;
  border-bottom-color: #375d81;
}

.borderYBlue800 {
  border-top-color: #204664;
  border-bottom-color: #204664;
}

.borderYBlue900 {
  border-top-color: #1b3b59;
  border-bottom-color: #1b3b59;
}

.borderYBlue950 {
  border-top-color: #193148;
  border-bottom-color: #193148;
}

.borderYBuyerprimary {
  border-top-color: #c2a661;
  border-bottom-color: #c2a661;
}

.borderYBuyerprimaryiphone {
  border-top-color: #cca738;
  border-bottom-color: #cca738;
}

.borderYBuyersecondary {
  border-top-color: #a48e45;
  border-bottom-color: #a48e45;
}

.borderYBuyertertiary {
  border-top-color: #f6f3eb;
  border-bottom-color: #f6f3eb;
}

.borderYBuyertertiaryalt {
  border-top-color: #e8dfcd;
  border-bottom-color: #e8dfcd;
}

.borderYCharcoal {
  border-top-color: #444;
  border-bottom-color: #444;
}

.borderYCyan200 {
  border-top-color: #c6e6f1;
  border-bottom-color: #c6e6f1;
}

.borderYCyan50 {
  border-top-color: #e8f4f9;
  border-bottom-color: #e8f4f9;
}

.borderYCyan600 {
  border-top-color: #2683a3;
  border-bottom-color: #2683a3;
}

.borderYDealerprimary {
  border-top-color: #436b93;
  border-bottom-color: #436b93;
}

.borderYDealersecondary {
  border-top-color: #204664;
  border-bottom-color: #204664;
}

.borderYDealertertiary {
  border-top-color: #dee3eb;
  border-bottom-color: #dee3eb;
}

.borderYDealertertiaryalt {
  border-top-color: #d8ecf5;
  border-bottom-color: #d8ecf5;
}

.borderYDolphin {
  border-top-color: #ddd;
  border-bottom-color: #ddd;
}

.borderYFacebookprimary {
  border-top-color: #3b5998;
  border-bottom-color: #3b5998;
}

.borderYGray500 {
  border-top-color: #888;
  border-bottom-color: #888;
}

.borderYGray700 {
  border-top-color: #555;
  border-bottom-color: #555;
}

.borderYGreen100 {
  border-top-color: #e5f4de;
  border-bottom-color: #e5f4de;
}

.borderYGreen200 {
  border-top-color: #ceecc1;
  border-bottom-color: #ceecc1;
}

.borderYGreen50 {
  border-top-color: #eff9ea;
  border-bottom-color: #eff9ea;
}

.borderYGreen700 {
  border-top-color: #418f48;
  border-bottom-color: #418f48;
}

.borderYGreen900 {
  border-top-color: #1b5633;
  border-bottom-color: #1b5633;
}

.borderYGreen950 {
  border-top-color: #0e3c1e;
  border-bottom-color: #0e3c1e;
}

.borderYInstagramprimary {
  border-top-color: #275a85;
  border-bottom-color: #275a85;
}

.borderYInternalprimary {
  border-top-color: #559b5e;
  border-bottom-color: #559b5e;
}

.borderYInternalsecondary {
  border-top-color: #2a7140;
  border-bottom-color: #2a7140;
}

.borderYInternaltertiary {
  border-top-color: #b8e7c8;
  border-bottom-color: #b8e7c8;
}

.borderYIron {
  border-top-color: #888;
  border-bottom-color: #888;
}

.borderYLinkedinprimary {
  border-top-color: #007bb6;
  border-bottom-color: #007bb6;
}

.borderYMarketingsale {
  border-top-color: #950808;
  border-bottom-color: #950808;
}

.borderYMedalbronze {
  border-top-color: #a06931;
  border-bottom-color: #a06931;
}

.borderYMedalgold {
  border-top-color: #8c7236;
  border-bottom-color: #8c7236;
}

.borderYMedalplatinum {
  border-top-color: #727383;
  border-bottom-color: #727383;
}

.borderYMedalsilver {
  border-top-color: #637583;
  border-bottom-color: #637583;
}

.borderYMoonstone {
  border-top-color: #f3f3f3;
  border-bottom-color: #f3f3f3;
}

.borderYNftwine {
  border-top-color: #610034;
  border-bottom-color: #610034;
}

.borderYNoir {
  border-top-color: #222;
  border-bottom-color: #222;
}

.borderYOverlaybackground {
  border-top-color: #000000b3;
  border-bottom-color: #000000b3;
}

.borderYOverlaylight {
  border-top-color: #fff6;
  border-bottom-color: #fff6;
}

.borderYPinterestprimary {
  border-top-color: #e00002;
  border-bottom-color: #e00002;
}

.borderYPitchblack {
  border-top-color: #000;
  border-bottom-color: #000;
}

.borderYRed200 {
  border-top-color: #fecaca;
  border-bottom-color: #fecaca;
}

.borderYRed50 {
  border-top-color: #fef2f2;
  border-bottom-color: #fef2f2;
}

.borderYRed700 {
  border-top-color: #c00;
  border-bottom-color: #c00;
}

.borderYRed900 {
  border-top-color: #770303;
  border-bottom-color: #770303;
}

.borderYSatan {
  border-top-color: #666;
  border-bottom-color: #666;
}

.borderYShagreen200 {
  border-top-color: #cbdbdb;
  border-bottom-color: #cbdbdb;
}

.borderYShagreen300 {
  border-top-color: #bacecc;
  border-bottom-color: #bacecc;
}

.borderYShagreen400 {
  border-top-color: #aabeb9;
  border-bottom-color: #aabeb9;
}

.borderYShagreen50 {
  border-top-color: #edf3f3;
  border-bottom-color: #edf3f3;
}

.borderYShagreen500 {
  border-top-color: #8fa8aa;
  border-bottom-color: #8fa8aa;
}

.borderYShagreen600 {
  border-top-color: #73919b;
  border-bottom-color: #73919b;
}

.borderYShagreen700 {
  border-top-color: #517884;
  border-bottom-color: #517884;
}

.borderYShagreen800 {
  border-top-color: #2e5f6c;
  border-bottom-color: #2e5f6c;
}

.borderYShagreen950 {
  border-top-color: #223f4c;
  border-bottom-color: #223f4c;
}

.borderYStorm {
  border-top-color: #bbb;
  border-bottom-color: #bbb;
}

.borderYSuccessprimary {
  border-top-color: #66a559;
  border-bottom-color: #66a559;
}

.borderYSuccesssecondary {
  border-top-color: #2a7140;
  border-bottom-color: #2a7140;
}

.borderYSuccesstertiary {
  border-top-color: #aedfa3;
  border-bottom-color: #aedfa3;
}

.borderYTangerine500 {
  border-top-color: #f9902d;
  border-bottom-color: #f9902d;
}

.borderYTangerine700 {
  border-top-color: #d26a00;
  border-bottom-color: #d26a00;
}

.borderYTrademember {
  border-top-color: #666;
  border-bottom-color: #666;
}

.borderYTradeprimary {
  border-top-color: #0f8d88;
  border-bottom-color: #0f8d88;
}

.borderYTradesecondary {
  border-top-color: #0d7d77;
  border-bottom-color: #0d7d77;
}

.borderYTradetertiary {
  border-top-color: #e7f4f3;
  border-bottom-color: #e7f4f3;
}

.borderYWarningprimary {
  border-top-color: #f07f04;
  border-bottom-color: #f07f04;
}

.borderYWarningsecondary {
  border-top-color: #ffc100;
  border-bottom-color: #ffc100;
}

.borderYWhite {
  border-top-color: #fff;
  border-bottom-color: #fff;
}

.borderYYellow100 {
  border-top-color: #ffe9b6;
  border-bottom-color: #ffe9b6;
}

.borderYYellow200 {
  border-top-color: #ffdf8b;
  border-bottom-color: #ffdf8b;
}

.borderYYellow300 {
  border-top-color: #ffd660;
  border-bottom-color: #ffd660;
}

.borderYYellow50 {
  border-top-color: #fff4e0;
  border-bottom-color: #fff4e0;
}

.borderYYellow700 {
  border-top-color: #be9116;
  border-bottom-color: #be9116;
}

.borderYYellow950 {
  border-top-color: #5d4a20;
  border-bottom-color: #5d4a20;
}

.borderYCurrent {
  border-top-color: currentColor;
  border-bottom-color: currentColor;
}

.borderYTransparent {
  border-top-color: #0000;
  border-bottom-color: #0000;
}

.borderBAlabaster {
  border-bottom-color: #fbfbfb;
}

.borderBAlertprimary {
  border-bottom-color: #c00;
}

.borderBAlertsecondary {
  border-bottom-color: #950808;
}

.borderBAlerttertiary {
  border-bottom-color: #fedcde;
}

.borderBBlue400 {
  border-bottom-color: #819cc5;
}

.borderBBlue50 {
  border-bottom-color: #eef1f7;
}

.borderBBlue700 {
  border-bottom-color: #375d81;
}

.borderBBlue800 {
  border-bottom-color: #204664;
}

.borderBBlue900 {
  border-bottom-color: #1b3b59;
}

.borderBBlue950 {
  border-bottom-color: #193148;
}

.borderBBuyerprimary {
  border-bottom-color: #c2a661;
}

.borderBBuyerprimaryiphone {
  border-bottom-color: #cca738;
}

.borderBBuyersecondary {
  border-bottom-color: #a48e45;
}

.borderBBuyertertiary {
  border-bottom-color: #f6f3eb;
}

.borderBBuyertertiaryalt {
  border-bottom-color: #e8dfcd;
}

.borderBCharcoal {
  border-bottom-color: #444;
}

.borderBCyan200 {
  border-bottom-color: #c6e6f1;
}

.borderBCyan50 {
  border-bottom-color: #e8f4f9;
}

.borderBCyan600 {
  border-bottom-color: #2683a3;
}

.borderBDealerprimary {
  border-bottom-color: #436b93;
}

.borderBDealersecondary {
  border-bottom-color: #204664;
}

.borderBDealertertiary {
  border-bottom-color: #dee3eb;
}

.borderBDealertertiaryalt {
  border-bottom-color: #d8ecf5;
}

.borderBDolphin {
  border-bottom-color: #ddd;
}

.borderBFacebookprimary {
  border-bottom-color: #3b5998;
}

.borderBGray500 {
  border-bottom-color: #888;
}

.borderBGray700 {
  border-bottom-color: #555;
}

.borderBGreen100 {
  border-bottom-color: #e5f4de;
}

.borderBGreen200 {
  border-bottom-color: #ceecc1;
}

.borderBGreen50 {
  border-bottom-color: #eff9ea;
}

.borderBGreen700 {
  border-bottom-color: #418f48;
}

.borderBGreen900 {
  border-bottom-color: #1b5633;
}

.borderBGreen950 {
  border-bottom-color: #0e3c1e;
}

.borderBInstagramprimary {
  border-bottom-color: #275a85;
}

.borderBInternalprimary {
  border-bottom-color: #559b5e;
}

.borderBInternalsecondary {
  border-bottom-color: #2a7140;
}

.borderBInternaltertiary {
  border-bottom-color: #b8e7c8;
}

.borderBIron {
  border-bottom-color: #888;
}

.borderBLinkedinprimary {
  border-bottom-color: #007bb6;
}

.borderBMarketingsale {
  border-bottom-color: #950808;
}

.borderBMedalbronze {
  border-bottom-color: #a06931;
}

.borderBMedalgold {
  border-bottom-color: #8c7236;
}

.borderBMedalplatinum {
  border-bottom-color: #727383;
}

.borderBMedalsilver {
  border-bottom-color: #637583;
}

.borderBMoonstone {
  border-bottom-color: #f3f3f3;
}

.borderBNftwine {
  border-bottom-color: #610034;
}

.borderBNoir {
  border-bottom-color: #222;
}

.borderBOverlaybackground {
  border-bottom-color: #000000b3;
}

.borderBOverlaylight {
  border-bottom-color: #fff6;
}

.borderBPinterestprimary {
  border-bottom-color: #e00002;
}

.borderBPitchblack {
  border-bottom-color: #000;
}

.borderBRed200 {
  border-bottom-color: #fecaca;
}

.borderBRed50 {
  border-bottom-color: #fef2f2;
}

.borderBRed700 {
  border-bottom-color: #c00;
}

.borderBRed900 {
  border-bottom-color: #770303;
}

.borderBSatan {
  border-bottom-color: #666;
}

.borderBShagreen200 {
  border-bottom-color: #cbdbdb;
}

.borderBShagreen300 {
  border-bottom-color: #bacecc;
}

.borderBShagreen400 {
  border-bottom-color: #aabeb9;
}

.borderBShagreen50 {
  border-bottom-color: #edf3f3;
}

.borderBShagreen500 {
  border-bottom-color: #8fa8aa;
}

.borderBShagreen600 {
  border-bottom-color: #73919b;
}

.borderBShagreen700 {
  border-bottom-color: #517884;
}

.borderBShagreen800 {
  border-bottom-color: #2e5f6c;
}

.borderBShagreen950 {
  border-bottom-color: #223f4c;
}

.borderBStorm {
  border-bottom-color: #bbb;
}

.borderBSuccessprimary {
  border-bottom-color: #66a559;
}

.borderBSuccesssecondary {
  border-bottom-color: #2a7140;
}

.borderBSuccesstertiary {
  border-bottom-color: #aedfa3;
}

.borderBTangerine500 {
  border-bottom-color: #f9902d;
}

.borderBTangerine700 {
  border-bottom-color: #d26a00;
}

.borderBTrademember {
  border-bottom-color: #666;
}

.borderBTradeprimary {
  border-bottom-color: #0f8d88;
}

.borderBTradesecondary {
  border-bottom-color: #0d7d77;
}

.borderBTradetertiary {
  border-bottom-color: #e7f4f3;
}

.borderBWarningprimary {
  border-bottom-color: #f07f04;
}

.borderBWarningsecondary {
  border-bottom-color: #ffc100;
}

.borderBWhite {
  border-bottom-color: #fff;
}

.borderBYellow100 {
  border-bottom-color: #ffe9b6;
}

.borderBYellow200 {
  border-bottom-color: #ffdf8b;
}

.borderBYellow300 {
  border-bottom-color: #ffd660;
}

.borderBYellow50 {
  border-bottom-color: #fff4e0;
}

.borderBYellow700 {
  border-bottom-color: #be9116;
}

.borderBYellow950 {
  border-bottom-color: #5d4a20;
}

.borderBCurrent {
  border-bottom-color: currentColor;
}

.borderBTransparent {
  border-bottom-color: #0000;
}

.borderEAlabaster {
  border-inline-end-color: #fbfbfb;
}

.borderEAlertprimary {
  border-inline-end-color: #c00;
}

.borderEAlertsecondary {
  border-inline-end-color: #950808;
}

.borderEAlerttertiary {
  border-inline-end-color: #fedcde;
}

.borderEBlue400 {
  border-inline-end-color: #819cc5;
}

.borderEBlue50 {
  border-inline-end-color: #eef1f7;
}

.borderEBlue700 {
  border-inline-end-color: #375d81;
}

.borderEBlue800 {
  border-inline-end-color: #204664;
}

.borderEBlue900 {
  border-inline-end-color: #1b3b59;
}

.borderEBlue950 {
  border-inline-end-color: #193148;
}

.borderEBuyerprimary {
  border-inline-end-color: #c2a661;
}

.borderEBuyerprimaryiphone {
  border-inline-end-color: #cca738;
}

.borderEBuyersecondary {
  border-inline-end-color: #a48e45;
}

.borderEBuyertertiary {
  border-inline-end-color: #f6f3eb;
}

.borderEBuyertertiaryalt {
  border-inline-end-color: #e8dfcd;
}

.borderECharcoal {
  border-inline-end-color: #444;
}

.borderECyan200 {
  border-inline-end-color: #c6e6f1;
}

.borderECyan50 {
  border-inline-end-color: #e8f4f9;
}

.borderECyan600 {
  border-inline-end-color: #2683a3;
}

.borderEDealerprimary {
  border-inline-end-color: #436b93;
}

.borderEDealersecondary {
  border-inline-end-color: #204664;
}

.borderEDealertertiary {
  border-inline-end-color: #dee3eb;
}

.borderEDealertertiaryalt {
  border-inline-end-color: #d8ecf5;
}

.borderEDolphin {
  border-inline-end-color: #ddd;
}

.borderEFacebookprimary {
  border-inline-end-color: #3b5998;
}

.borderEGray500 {
  border-inline-end-color: #888;
}

.borderEGray700 {
  border-inline-end-color: #555;
}

.borderEGreen100 {
  border-inline-end-color: #e5f4de;
}

.borderEGreen200 {
  border-inline-end-color: #ceecc1;
}

.borderEGreen50 {
  border-inline-end-color: #eff9ea;
}

.borderEGreen700 {
  border-inline-end-color: #418f48;
}

.borderEGreen900 {
  border-inline-end-color: #1b5633;
}

.borderEGreen950 {
  border-inline-end-color: #0e3c1e;
}

.borderEInstagramprimary {
  border-inline-end-color: #275a85;
}

.borderEInternalprimary {
  border-inline-end-color: #559b5e;
}

.borderEInternalsecondary {
  border-inline-end-color: #2a7140;
}

.borderEInternaltertiary {
  border-inline-end-color: #b8e7c8;
}

.borderEIron {
  border-inline-end-color: #888;
}

.borderELinkedinprimary {
  border-inline-end-color: #007bb6;
}

.borderEMarketingsale {
  border-inline-end-color: #950808;
}

.borderEMedalbronze {
  border-inline-end-color: #a06931;
}

.borderEMedalgold {
  border-inline-end-color: #8c7236;
}

.borderEMedalplatinum {
  border-inline-end-color: #727383;
}

.borderEMedalsilver {
  border-inline-end-color: #637583;
}

.borderEMoonstone {
  border-inline-end-color: #f3f3f3;
}

.borderENftwine {
  border-inline-end-color: #610034;
}

.borderENoir {
  border-inline-end-color: #222;
}

.borderEOverlaybackground {
  border-inline-end-color: #000000b3;
}

.borderEOverlaylight {
  border-inline-end-color: #fff6;
}

.borderEPinterestprimary {
  border-inline-end-color: #e00002;
}

.borderEPitchblack {
  border-inline-end-color: #000;
}

.borderERed200 {
  border-inline-end-color: #fecaca;
}

.borderERed50 {
  border-inline-end-color: #fef2f2;
}

.borderERed700 {
  border-inline-end-color: #c00;
}

.borderERed900 {
  border-inline-end-color: #770303;
}

.borderESatan {
  border-inline-end-color: #666;
}

.borderEShagreen200 {
  border-inline-end-color: #cbdbdb;
}

.borderEShagreen300 {
  border-inline-end-color: #bacecc;
}

.borderEShagreen400 {
  border-inline-end-color: #aabeb9;
}

.borderEShagreen50 {
  border-inline-end-color: #edf3f3;
}

.borderEShagreen500 {
  border-inline-end-color: #8fa8aa;
}

.borderEShagreen600 {
  border-inline-end-color: #73919b;
}

.borderEShagreen700 {
  border-inline-end-color: #517884;
}

.borderEShagreen800 {
  border-inline-end-color: #2e5f6c;
}

.borderEShagreen950 {
  border-inline-end-color: #223f4c;
}

.borderEStorm {
  border-inline-end-color: #bbb;
}

.borderESuccessprimary {
  border-inline-end-color: #66a559;
}

.borderESuccesssecondary {
  border-inline-end-color: #2a7140;
}

.borderESuccesstertiary {
  border-inline-end-color: #aedfa3;
}

.borderETangerine500 {
  border-inline-end-color: #f9902d;
}

.borderETangerine700 {
  border-inline-end-color: #d26a00;
}

.borderETrademember {
  border-inline-end-color: #666;
}

.borderETradeprimary {
  border-inline-end-color: #0f8d88;
}

.borderETradesecondary {
  border-inline-end-color: #0d7d77;
}

.borderETradetertiary {
  border-inline-end-color: #e7f4f3;
}

.borderEWarningprimary {
  border-inline-end-color: #f07f04;
}

.borderEWarningsecondary {
  border-inline-end-color: #ffc100;
}

.borderEWhite {
  border-inline-end-color: #fff;
}

.borderEYellow100 {
  border-inline-end-color: #ffe9b6;
}

.borderEYellow200 {
  border-inline-end-color: #ffdf8b;
}

.borderEYellow300 {
  border-inline-end-color: #ffd660;
}

.borderEYellow50 {
  border-inline-end-color: #fff4e0;
}

.borderEYellow700 {
  border-inline-end-color: #be9116;
}

.borderEYellow950 {
  border-inline-end-color: #5d4a20;
}

.borderECurrent {
  border-inline-end-color: currentColor;
}

.borderETransparent {
  border-inline-end-color: #0000;
}

.borderLAlabaster {
  border-left-color: #fbfbfb;
}

.borderLAlertprimary {
  border-left-color: #c00;
}

.borderLAlertsecondary {
  border-left-color: #950808;
}

.borderLAlerttertiary {
  border-left-color: #fedcde;
}

.borderLBlue400 {
  border-left-color: #819cc5;
}

.borderLBlue50 {
  border-left-color: #eef1f7;
}

.borderLBlue700 {
  border-left-color: #375d81;
}

.borderLBlue800 {
  border-left-color: #204664;
}

.borderLBlue900 {
  border-left-color: #1b3b59;
}

.borderLBlue950 {
  border-left-color: #193148;
}

.borderLBuyerprimary {
  border-left-color: #c2a661;
}

.borderLBuyerprimaryiphone {
  border-left-color: #cca738;
}

.borderLBuyersecondary {
  border-left-color: #a48e45;
}

.borderLBuyertertiary {
  border-left-color: #f6f3eb;
}

.borderLBuyertertiaryalt {
  border-left-color: #e8dfcd;
}

.borderLCharcoal {
  border-left-color: #444;
}

.borderLCyan200 {
  border-left-color: #c6e6f1;
}

.borderLCyan50 {
  border-left-color: #e8f4f9;
}

.borderLCyan600 {
  border-left-color: #2683a3;
}

.borderLDealerprimary {
  border-left-color: #436b93;
}

.borderLDealersecondary {
  border-left-color: #204664;
}

.borderLDealertertiary {
  border-left-color: #dee3eb;
}

.borderLDealertertiaryalt {
  border-left-color: #d8ecf5;
}

.borderLDolphin {
  border-left-color: #ddd;
}

.borderLFacebookprimary {
  border-left-color: #3b5998;
}

.borderLGray500 {
  border-left-color: #888;
}

.borderLGray700 {
  border-left-color: #555;
}

.borderLGreen100 {
  border-left-color: #e5f4de;
}

.borderLGreen200 {
  border-left-color: #ceecc1;
}

.borderLGreen50 {
  border-left-color: #eff9ea;
}

.borderLGreen700 {
  border-left-color: #418f48;
}

.borderLGreen900 {
  border-left-color: #1b5633;
}

.borderLGreen950 {
  border-left-color: #0e3c1e;
}

.borderLInstagramprimary {
  border-left-color: #275a85;
}

.borderLInternalprimary {
  border-left-color: #559b5e;
}

.borderLInternalsecondary {
  border-left-color: #2a7140;
}

.borderLInternaltertiary {
  border-left-color: #b8e7c8;
}

.borderLIron {
  border-left-color: #888;
}

.borderLLinkedinprimary {
  border-left-color: #007bb6;
}

.borderLMarketingsale {
  border-left-color: #950808;
}

.borderLMedalbronze {
  border-left-color: #a06931;
}

.borderLMedalgold {
  border-left-color: #8c7236;
}

.borderLMedalplatinum {
  border-left-color: #727383;
}

.borderLMedalsilver {
  border-left-color: #637583;
}

.borderLMoonstone {
  border-left-color: #f3f3f3;
}

.borderLNftwine {
  border-left-color: #610034;
}

.borderLNoir {
  border-left-color: #222;
}

.borderLOverlaybackground {
  border-left-color: #000000b3;
}

.borderLOverlaylight {
  border-left-color: #fff6;
}

.borderLPinterestprimary {
  border-left-color: #e00002;
}

.borderLPitchblack {
  border-left-color: #000;
}

.borderLRed200 {
  border-left-color: #fecaca;
}

.borderLRed50 {
  border-left-color: #fef2f2;
}

.borderLRed700 {
  border-left-color: #c00;
}

.borderLRed900 {
  border-left-color: #770303;
}

.borderLSatan {
  border-left-color: #666;
}

.borderLShagreen200 {
  border-left-color: #cbdbdb;
}

.borderLShagreen300 {
  border-left-color: #bacecc;
}

.borderLShagreen400 {
  border-left-color: #aabeb9;
}

.borderLShagreen50 {
  border-left-color: #edf3f3;
}

.borderLShagreen500 {
  border-left-color: #8fa8aa;
}

.borderLShagreen600 {
  border-left-color: #73919b;
}

.borderLShagreen700 {
  border-left-color: #517884;
}

.borderLShagreen800 {
  border-left-color: #2e5f6c;
}

.borderLShagreen950 {
  border-left-color: #223f4c;
}

.borderLStorm {
  border-left-color: #bbb;
}

.borderLSuccessprimary {
  border-left-color: #66a559;
}

.borderLSuccesssecondary {
  border-left-color: #2a7140;
}

.borderLSuccesstertiary {
  border-left-color: #aedfa3;
}

.borderLTangerine500 {
  border-left-color: #f9902d;
}

.borderLTangerine700 {
  border-left-color: #d26a00;
}

.borderLTrademember {
  border-left-color: #666;
}

.borderLTradeprimary {
  border-left-color: #0f8d88;
}

.borderLTradesecondary {
  border-left-color: #0d7d77;
}

.borderLTradetertiary {
  border-left-color: #e7f4f3;
}

.borderLWarningprimary {
  border-left-color: #f07f04;
}

.borderLWarningsecondary {
  border-left-color: #ffc100;
}

.borderLWhite {
  border-left-color: #fff;
}

.borderLYellow100 {
  border-left-color: #ffe9b6;
}

.borderLYellow200 {
  border-left-color: #ffdf8b;
}

.borderLYellow300 {
  border-left-color: #ffd660;
}

.borderLYellow50 {
  border-left-color: #fff4e0;
}

.borderLYellow700 {
  border-left-color: #be9116;
}

.borderLYellow950 {
  border-left-color: #5d4a20;
}

.borderLCurrent {
  border-left-color: currentColor;
}

.borderLTransparent {
  border-left-color: #0000;
}

.borderRAlabaster {
  border-right-color: #fbfbfb;
}

.borderRAlertprimary {
  border-right-color: #c00;
}

.borderRAlertsecondary {
  border-right-color: #950808;
}

.borderRAlerttertiary {
  border-right-color: #fedcde;
}

.borderRBlue400 {
  border-right-color: #819cc5;
}

.borderRBlue50 {
  border-right-color: #eef1f7;
}

.borderRBlue700 {
  border-right-color: #375d81;
}

.borderRBlue800 {
  border-right-color: #204664;
}

.borderRBlue900 {
  border-right-color: #1b3b59;
}

.borderRBlue950 {
  border-right-color: #193148;
}

.borderRBuyerprimary {
  border-right-color: #c2a661;
}

.borderRBuyerprimaryiphone {
  border-right-color: #cca738;
}

.borderRBuyersecondary {
  border-right-color: #a48e45;
}

.borderRBuyertertiary {
  border-right-color: #f6f3eb;
}

.borderRBuyertertiaryalt {
  border-right-color: #e8dfcd;
}

.borderRCharcoal {
  border-right-color: #444;
}

.borderRCyan200 {
  border-right-color: #c6e6f1;
}

.borderRCyan50 {
  border-right-color: #e8f4f9;
}

.borderRCyan600 {
  border-right-color: #2683a3;
}

.borderRDealerprimary {
  border-right-color: #436b93;
}

.borderRDealersecondary {
  border-right-color: #204664;
}

.borderRDealertertiary {
  border-right-color: #dee3eb;
}

.borderRDealertertiaryalt {
  border-right-color: #d8ecf5;
}

.borderRDolphin {
  border-right-color: #ddd;
}

.borderRFacebookprimary {
  border-right-color: #3b5998;
}

.borderRGray500 {
  border-right-color: #888;
}

.borderRGray700 {
  border-right-color: #555;
}

.borderRGreen100 {
  border-right-color: #e5f4de;
}

.borderRGreen200 {
  border-right-color: #ceecc1;
}

.borderRGreen50 {
  border-right-color: #eff9ea;
}

.borderRGreen700 {
  border-right-color: #418f48;
}

.borderRGreen900 {
  border-right-color: #1b5633;
}

.borderRGreen950 {
  border-right-color: #0e3c1e;
}

.borderRInstagramprimary {
  border-right-color: #275a85;
}

.borderRInternalprimary {
  border-right-color: #559b5e;
}

.borderRInternalsecondary {
  border-right-color: #2a7140;
}

.borderRInternaltertiary {
  border-right-color: #b8e7c8;
}

.borderRIron {
  border-right-color: #888;
}

.borderRLinkedinprimary {
  border-right-color: #007bb6;
}

.borderRMarketingsale {
  border-right-color: #950808;
}

.borderRMedalbronze {
  border-right-color: #a06931;
}

.borderRMedalgold {
  border-right-color: #8c7236;
}

.borderRMedalplatinum {
  border-right-color: #727383;
}

.borderRMedalsilver {
  border-right-color: #637583;
}

.borderRMoonstone {
  border-right-color: #f3f3f3;
}

.borderRNftwine {
  border-right-color: #610034;
}

.borderRNoir {
  border-right-color: #222;
}

.borderROverlaybackground {
  border-right-color: #000000b3;
}

.borderROverlaylight {
  border-right-color: #fff6;
}

.borderRPinterestprimary {
  border-right-color: #e00002;
}

.borderRPitchblack {
  border-right-color: #000;
}

.borderRRed200 {
  border-right-color: #fecaca;
}

.borderRRed50 {
  border-right-color: #fef2f2;
}

.borderRRed700 {
  border-right-color: #c00;
}

.borderRRed900 {
  border-right-color: #770303;
}

.borderRSatan {
  border-right-color: #666;
}

.borderRShagreen200 {
  border-right-color: #cbdbdb;
}

.borderRShagreen300 {
  border-right-color: #bacecc;
}

.borderRShagreen400 {
  border-right-color: #aabeb9;
}

.borderRShagreen50 {
  border-right-color: #edf3f3;
}

.borderRShagreen500 {
  border-right-color: #8fa8aa;
}

.borderRShagreen600 {
  border-right-color: #73919b;
}

.borderRShagreen700 {
  border-right-color: #517884;
}

.borderRShagreen800 {
  border-right-color: #2e5f6c;
}

.borderRShagreen950 {
  border-right-color: #223f4c;
}

.borderRStorm {
  border-right-color: #bbb;
}

.borderRSuccessprimary {
  border-right-color: #66a559;
}

.borderRSuccesssecondary {
  border-right-color: #2a7140;
}

.borderRSuccesstertiary {
  border-right-color: #aedfa3;
}

.borderRTangerine500 {
  border-right-color: #f9902d;
}

.borderRTangerine700 {
  border-right-color: #d26a00;
}

.borderRTrademember {
  border-right-color: #666;
}

.borderRTradeprimary {
  border-right-color: #0f8d88;
}

.borderRTradesecondary {
  border-right-color: #0d7d77;
}

.borderRTradetertiary {
  border-right-color: #e7f4f3;
}

.borderRWarningprimary {
  border-right-color: #f07f04;
}

.borderRWarningsecondary {
  border-right-color: #ffc100;
}

.borderRWhite {
  border-right-color: #fff;
}

.borderRYellow100 {
  border-right-color: #ffe9b6;
}

.borderRYellow200 {
  border-right-color: #ffdf8b;
}

.borderRYellow300 {
  border-right-color: #ffd660;
}

.borderRYellow50 {
  border-right-color: #fff4e0;
}

.borderRYellow700 {
  border-right-color: #be9116;
}

.borderRYellow950 {
  border-right-color: #5d4a20;
}

.borderRCurrent {
  border-right-color: currentColor;
}

.borderRTransparent {
  border-right-color: #0000;
}

.borderSAlabaster {
  border-inline-start-color: #fbfbfb;
}

.borderSAlertprimary {
  border-inline-start-color: #c00;
}

.borderSAlertsecondary {
  border-inline-start-color: #950808;
}

.borderSAlerttertiary {
  border-inline-start-color: #fedcde;
}

.borderSBlue400 {
  border-inline-start-color: #819cc5;
}

.borderSBlue50 {
  border-inline-start-color: #eef1f7;
}

.borderSBlue700 {
  border-inline-start-color: #375d81;
}

.borderSBlue800 {
  border-inline-start-color: #204664;
}

.borderSBlue900 {
  border-inline-start-color: #1b3b59;
}

.borderSBlue950 {
  border-inline-start-color: #193148;
}

.borderSBuyerprimary {
  border-inline-start-color: #c2a661;
}

.borderSBuyerprimaryiphone {
  border-inline-start-color: #cca738;
}

.borderSBuyersecondary {
  border-inline-start-color: #a48e45;
}

.borderSBuyertertiary {
  border-inline-start-color: #f6f3eb;
}

.borderSBuyertertiaryalt {
  border-inline-start-color: #e8dfcd;
}

.borderSCharcoal {
  border-inline-start-color: #444;
}

.borderSCyan200 {
  border-inline-start-color: #c6e6f1;
}

.borderSCyan50 {
  border-inline-start-color: #e8f4f9;
}

.borderSCyan600 {
  border-inline-start-color: #2683a3;
}

.borderSDealerprimary {
  border-inline-start-color: #436b93;
}

.borderSDealersecondary {
  border-inline-start-color: #204664;
}

.borderSDealertertiary {
  border-inline-start-color: #dee3eb;
}

.borderSDealertertiaryalt {
  border-inline-start-color: #d8ecf5;
}

.borderSDolphin {
  border-inline-start-color: #ddd;
}

.borderSFacebookprimary {
  border-inline-start-color: #3b5998;
}

.borderSGray500 {
  border-inline-start-color: #888;
}

.borderSGray700 {
  border-inline-start-color: #555;
}

.borderSGreen100 {
  border-inline-start-color: #e5f4de;
}

.borderSGreen200 {
  border-inline-start-color: #ceecc1;
}

.borderSGreen50 {
  border-inline-start-color: #eff9ea;
}

.borderSGreen700 {
  border-inline-start-color: #418f48;
}

.borderSGreen900 {
  border-inline-start-color: #1b5633;
}

.borderSGreen950 {
  border-inline-start-color: #0e3c1e;
}

.borderSInstagramprimary {
  border-inline-start-color: #275a85;
}

.borderSInternalprimary {
  border-inline-start-color: #559b5e;
}

.borderSInternalsecondary {
  border-inline-start-color: #2a7140;
}

.borderSInternaltertiary {
  border-inline-start-color: #b8e7c8;
}

.borderSIron {
  border-inline-start-color: #888;
}

.borderSLinkedinprimary {
  border-inline-start-color: #007bb6;
}

.borderSMarketingsale {
  border-inline-start-color: #950808;
}

.borderSMedalbronze {
  border-inline-start-color: #a06931;
}

.borderSMedalgold {
  border-inline-start-color: #8c7236;
}

.borderSMedalplatinum {
  border-inline-start-color: #727383;
}

.borderSMedalsilver {
  border-inline-start-color: #637583;
}

.borderSMoonstone {
  border-inline-start-color: #f3f3f3;
}

.borderSNftwine {
  border-inline-start-color: #610034;
}

.borderSNoir {
  border-inline-start-color: #222;
}

.borderSOverlaybackground {
  border-inline-start-color: #000000b3;
}

.borderSOverlaylight {
  border-inline-start-color: #fff6;
}

.borderSPinterestprimary {
  border-inline-start-color: #e00002;
}

.borderSPitchblack {
  border-inline-start-color: #000;
}

.borderSRed200 {
  border-inline-start-color: #fecaca;
}

.borderSRed50 {
  border-inline-start-color: #fef2f2;
}

.borderSRed700 {
  border-inline-start-color: #c00;
}

.borderSRed900 {
  border-inline-start-color: #770303;
}

.borderSSatan {
  border-inline-start-color: #666;
}

.borderSShagreen200 {
  border-inline-start-color: #cbdbdb;
}

.borderSShagreen300 {
  border-inline-start-color: #bacecc;
}

.borderSShagreen400 {
  border-inline-start-color: #aabeb9;
}

.borderSShagreen50 {
  border-inline-start-color: #edf3f3;
}

.borderSShagreen500 {
  border-inline-start-color: #8fa8aa;
}

.borderSShagreen600 {
  border-inline-start-color: #73919b;
}

.borderSShagreen700 {
  border-inline-start-color: #517884;
}

.borderSShagreen800 {
  border-inline-start-color: #2e5f6c;
}

.borderSShagreen950 {
  border-inline-start-color: #223f4c;
}

.borderSStorm {
  border-inline-start-color: #bbb;
}

.borderSSuccessprimary {
  border-inline-start-color: #66a559;
}

.borderSSuccesssecondary {
  border-inline-start-color: #2a7140;
}

.borderSSuccesstertiary {
  border-inline-start-color: #aedfa3;
}

.borderSTangerine500 {
  border-inline-start-color: #f9902d;
}

.borderSTangerine700 {
  border-inline-start-color: #d26a00;
}

.borderSTrademember {
  border-inline-start-color: #666;
}

.borderSTradeprimary {
  border-inline-start-color: #0f8d88;
}

.borderSTradesecondary {
  border-inline-start-color: #0d7d77;
}

.borderSTradetertiary {
  border-inline-start-color: #e7f4f3;
}

.borderSWarningprimary {
  border-inline-start-color: #f07f04;
}

.borderSWarningsecondary {
  border-inline-start-color: #ffc100;
}

.borderSWhite {
  border-inline-start-color: #fff;
}

.borderSYellow100 {
  border-inline-start-color: #ffe9b6;
}

.borderSYellow200 {
  border-inline-start-color: #ffdf8b;
}

.borderSYellow300 {
  border-inline-start-color: #ffd660;
}

.borderSYellow50 {
  border-inline-start-color: #fff4e0;
}

.borderSYellow700 {
  border-inline-start-color: #be9116;
}

.borderSYellow950 {
  border-inline-start-color: #5d4a20;
}

.borderSCurrent {
  border-inline-start-color: currentColor;
}

.borderSTransparent {
  border-inline-start-color: #0000;
}

.borderTAlabaster {
  border-top-color: #fbfbfb;
}

.borderTAlertprimary {
  border-top-color: #c00;
}

.borderTAlertsecondary {
  border-top-color: #950808;
}

.borderTAlerttertiary {
  border-top-color: #fedcde;
}

.borderTBlue400 {
  border-top-color: #819cc5;
}

.borderTBlue50 {
  border-top-color: #eef1f7;
}

.borderTBlue700 {
  border-top-color: #375d81;
}

.borderTBlue800 {
  border-top-color: #204664;
}

.borderTBlue900 {
  border-top-color: #1b3b59;
}

.borderTBlue950 {
  border-top-color: #193148;
}

.borderTBuyerprimary {
  border-top-color: #c2a661;
}

.borderTBuyerprimaryiphone {
  border-top-color: #cca738;
}

.borderTBuyersecondary {
  border-top-color: #a48e45;
}

.borderTBuyertertiary {
  border-top-color: #f6f3eb;
}

.borderTBuyertertiaryalt {
  border-top-color: #e8dfcd;
}

.borderTCharcoal {
  border-top-color: #444;
}

.borderTCyan200 {
  border-top-color: #c6e6f1;
}

.borderTCyan50 {
  border-top-color: #e8f4f9;
}

.borderTCyan600 {
  border-top-color: #2683a3;
}

.borderTDealerprimary {
  border-top-color: #436b93;
}

.borderTDealersecondary {
  border-top-color: #204664;
}

.borderTDealertertiary {
  border-top-color: #dee3eb;
}

.borderTDealertertiaryalt {
  border-top-color: #d8ecf5;
}

.borderTDolphin {
  border-top-color: #ddd;
}

.borderTFacebookprimary {
  border-top-color: #3b5998;
}

.borderTGray500 {
  border-top-color: #888;
}

.borderTGray700 {
  border-top-color: #555;
}

.borderTGreen100 {
  border-top-color: #e5f4de;
}

.borderTGreen200 {
  border-top-color: #ceecc1;
}

.borderTGreen50 {
  border-top-color: #eff9ea;
}

.borderTGreen700 {
  border-top-color: #418f48;
}

.borderTGreen900 {
  border-top-color: #1b5633;
}

.borderTGreen950 {
  border-top-color: #0e3c1e;
}

.borderTInstagramprimary {
  border-top-color: #275a85;
}

.borderTInternalprimary {
  border-top-color: #559b5e;
}

.borderTInternalsecondary {
  border-top-color: #2a7140;
}

.borderTInternaltertiary {
  border-top-color: #b8e7c8;
}

.borderTIron {
  border-top-color: #888;
}

.borderTLinkedinprimary {
  border-top-color: #007bb6;
}

.borderTMarketingsale {
  border-top-color: #950808;
}

.borderTMedalbronze {
  border-top-color: #a06931;
}

.borderTMedalgold {
  border-top-color: #8c7236;
}

.borderTMedalplatinum {
  border-top-color: #727383;
}

.borderTMedalsilver {
  border-top-color: #637583;
}

.borderTMoonstone {
  border-top-color: #f3f3f3;
}

.borderTNftwine {
  border-top-color: #610034;
}

.borderTNoir {
  border-top-color: #222;
}

.borderTOverlaybackground {
  border-top-color: #000000b3;
}

.borderTOverlaylight {
  border-top-color: #fff6;
}

.borderTPinterestprimary {
  border-top-color: #e00002;
}

.borderTPitchblack {
  border-top-color: #000;
}

.borderTRed200 {
  border-top-color: #fecaca;
}

.borderTRed50 {
  border-top-color: #fef2f2;
}

.borderTRed700 {
  border-top-color: #c00;
}

.borderTRed900 {
  border-top-color: #770303;
}

.borderTSatan {
  border-top-color: #666;
}

.borderTShagreen200 {
  border-top-color: #cbdbdb;
}

.borderTShagreen300 {
  border-top-color: #bacecc;
}

.borderTShagreen400 {
  border-top-color: #aabeb9;
}

.borderTShagreen50 {
  border-top-color: #edf3f3;
}

.borderTShagreen500 {
  border-top-color: #8fa8aa;
}

.borderTShagreen600 {
  border-top-color: #73919b;
}

.borderTShagreen700 {
  border-top-color: #517884;
}

.borderTShagreen800 {
  border-top-color: #2e5f6c;
}

.borderTShagreen950 {
  border-top-color: #223f4c;
}

.borderTStorm {
  border-top-color: #bbb;
}

.borderTSuccessprimary {
  border-top-color: #66a559;
}

.borderTSuccesssecondary {
  border-top-color: #2a7140;
}

.borderTSuccesstertiary {
  border-top-color: #aedfa3;
}

.borderTTangerine500 {
  border-top-color: #f9902d;
}

.borderTTangerine700 {
  border-top-color: #d26a00;
}

.borderTTrademember {
  border-top-color: #666;
}

.borderTTradeprimary {
  border-top-color: #0f8d88;
}

.borderTTradesecondary {
  border-top-color: #0d7d77;
}

.borderTTradetertiary {
  border-top-color: #e7f4f3;
}

.borderTWarningprimary {
  border-top-color: #f07f04;
}

.borderTWarningsecondary {
  border-top-color: #ffc100;
}

.borderTWhite {
  border-top-color: #fff;
}

.borderTYellow100 {
  border-top-color: #ffe9b6;
}

.borderTYellow200 {
  border-top-color: #ffdf8b;
}

.borderTYellow300 {
  border-top-color: #ffd660;
}

.borderTYellow50 {
  border-top-color: #fff4e0;
}

.borderTYellow700 {
  border-top-color: #be9116;
}

.borderTYellow950 {
  border-top-color: #5d4a20;
}

.borderTCurrent {
  border-top-color: currentColor;
}

.borderTTransparent {
  border-top-color: #0000;
}

.bgAlabaster {
  background-color: #fbfbfb;
}

.bgAlertprimary {
  background-color: #c00;
}

.bgAlertsecondary {
  background-color: #950808;
}

.bgAlerttertiary {
  background-color: #fedcde;
}

.bgBlue400 {
  background-color: #819cc5;
}

.bgBlue50 {
  background-color: #eef1f7;
}

.bgBlue700 {
  background-color: #375d81;
}

.bgBlue800 {
  background-color: #204664;
}

.bgBlue900 {
  background-color: #1b3b59;
}

.bgBlue950 {
  background-color: #193148;
}

.bgBuyerprimary {
  background-color: #c2a661;
}

.bgBuyerprimaryiphone {
  background-color: #cca738;
}

.bgBuyersecondary {
  background-color: #a48e45;
}

.bgBuyertertiary {
  background-color: #f6f3eb;
}

.bgBuyertertiaryalt {
  background-color: #e8dfcd;
}

.bgCharcoal {
  background-color: #444;
}

.bgCyan200 {
  background-color: #c6e6f1;
}

.bgCyan50 {
  background-color: #e8f4f9;
}

.bgCyan600 {
  background-color: #2683a3;
}

.bgDealerprimary {
  background-color: #436b93;
}

.bgDealersecondary {
  background-color: #204664;
}

.bgDealertertiary {
  background-color: #dee3eb;
}

.bgDealertertiaryalt {
  background-color: #d8ecf5;
}

.bgDolphin {
  background-color: #ddd;
}

.bgFacebookprimary {
  background-color: #3b5998;
}

.bgGray500 {
  background-color: #888;
}

.bgGray700 {
  background-color: #555;
}

.bgGreen100 {
  background-color: #e5f4de;
}

.bgGreen200 {
  background-color: #ceecc1;
}

.bgGreen50 {
  background-color: #eff9ea;
}

.bgGreen700 {
  background-color: #418f48;
}

.bgGreen900 {
  background-color: #1b5633;
}

.bgGreen950 {
  background-color: #0e3c1e;
}

.bgInstagramprimary {
  background-color: #275a85;
}

.bgInternalprimary {
  background-color: #559b5e;
}

.bgInternalsecondary {
  background-color: #2a7140;
}

.bgInternaltertiary {
  background-color: #b8e7c8;
}

.bgIron {
  background-color: #888;
}

.bgLinkedinprimary {
  background-color: #007bb6;
}

.bgMarketingsale {
  background-color: #950808;
}

.bgMedalbronze {
  background-color: #a06931;
}

.bgMedalgold {
  background-color: #8c7236;
}

.bgMedalplatinum {
  background-color: #727383;
}

.bgMedalsilver {
  background-color: #637583;
}

.bgMoonstone {
  background-color: #f3f3f3;
}

.bgNftwine {
  background-color: #610034;
}

.bgNoir {
  background-color: #222;
}

.bgOverlaybackground {
  background-color: #000000b3;
}

.bgOverlaylight {
  background-color: #fff6;
}

.bgPinterestprimary {
  background-color: #e00002;
}

.bgPitchblack {
  background-color: #000;
}

.bgRed200 {
  background-color: #fecaca;
}

.bgRed50 {
  background-color: #fef2f2;
}

.bgRed700 {
  background-color: #c00;
}

.bgRed900 {
  background-color: #770303;
}

.bgSatan {
  background-color: #666;
}

.bgShagreen200 {
  background-color: #cbdbdb;
}

.bgShagreen300 {
  background-color: #bacecc;
}

.bgShagreen400 {
  background-color: #aabeb9;
}

.bgShagreen50 {
  background-color: #edf3f3;
}

.bgShagreen500 {
  background-color: #8fa8aa;
}

.bgShagreen600 {
  background-color: #73919b;
}

.bgShagreen700 {
  background-color: #517884;
}

.bgShagreen800 {
  background-color: #2e5f6c;
}

.bgShagreen950 {
  background-color: #223f4c;
}

.bgStorm {
  background-color: #bbb;
}

.bgSuccessprimary {
  background-color: #66a559;
}

.bgSuccesssecondary {
  background-color: #2a7140;
}

.bgSuccesstertiary {
  background-color: #aedfa3;
}

.bgTangerine500 {
  background-color: #f9902d;
}

.bgTangerine700 {
  background-color: #d26a00;
}

.bgTrademember {
  background-color: #666;
}

.bgTradeprimary {
  background-color: #0f8d88;
}

.bgTradesecondary {
  background-color: #0d7d77;
}

.bgTradetertiary {
  background-color: #e7f4f3;
}

.bgWarningprimary {
  background-color: #f07f04;
}

.bgWarningsecondary {
  background-color: #ffc100;
}

.bgWhite {
  background-color: #fff;
}

.bgYellow100 {
  background-color: #ffe9b6;
}

.bgYellow200 {
  background-color: #ffdf8b;
}

.bgYellow300 {
  background-color: #ffd660;
}

.bgYellow50 {
  background-color: #fff4e0;
}

.bgYellow700 {
  background-color: #be9116;
}

.bgYellow950 {
  background-color: #5d4a20;
}

.bgCurrent {
  background-color: currentColor;
}

.bgTransparent {
  background-color: #0000;
}

.fillAlabaster {
  fill: #fbfbfb;
}

.fillAlertprimary {
  fill: #c00;
}

.fillAlertsecondary {
  fill: #950808;
}

.fillAlerttertiary {
  fill: #fedcde;
}

.fillBlue400 {
  fill: #819cc5;
}

.fillBlue50 {
  fill: #eef1f7;
}

.fillBlue700 {
  fill: #375d81;
}

.fillBlue800 {
  fill: #204664;
}

.fillBlue900 {
  fill: #1b3b59;
}

.fillBlue950 {
  fill: #193148;
}

.fillBuyerprimary {
  fill: #c2a661;
}

.fillBuyerprimaryiphone {
  fill: #cca738;
}

.fillBuyersecondary {
  fill: #a48e45;
}

.fillBuyertertiary {
  fill: #f6f3eb;
}

.fillBuyertertiaryalt {
  fill: #e8dfcd;
}

.fillCharcoal {
  fill: #444;
}

.fillCyan200 {
  fill: #c6e6f1;
}

.fillCyan50 {
  fill: #e8f4f9;
}

.fillCyan600 {
  fill: #2683a3;
}

.fillDealerprimary {
  fill: #436b93;
}

.fillDealersecondary {
  fill: #204664;
}

.fillDealertertiary {
  fill: #dee3eb;
}

.fillDealertertiaryalt {
  fill: #d8ecf5;
}

.fillDolphin {
  fill: #ddd;
}

.fillFacebookprimary {
  fill: #3b5998;
}

.fillGray500 {
  fill: #888;
}

.fillGray700 {
  fill: #555;
}

.fillGreen100 {
  fill: #e5f4de;
}

.fillGreen200 {
  fill: #ceecc1;
}

.fillGreen50 {
  fill: #eff9ea;
}

.fillGreen700 {
  fill: #418f48;
}

.fillGreen900 {
  fill: #1b5633;
}

.fillGreen950 {
  fill: #0e3c1e;
}

.fillInstagramprimary {
  fill: #275a85;
}

.fillInternalprimary {
  fill: #559b5e;
}

.fillInternalsecondary {
  fill: #2a7140;
}

.fillInternaltertiary {
  fill: #b8e7c8;
}

.fillIron {
  fill: #888;
}

.fillLinkedinprimary {
  fill: #007bb6;
}

.fillMarketingsale {
  fill: #950808;
}

.fillMedalbronze {
  fill: #a06931;
}

.fillMedalgold {
  fill: #8c7236;
}

.fillMedalplatinum {
  fill: #727383;
}

.fillMedalsilver {
  fill: #637583;
}

.fillMoonstone {
  fill: #f3f3f3;
}

.fillNftwine {
  fill: #610034;
}

.fillNoir {
  fill: #222;
}

.fillOverlaybackground {
  fill: #000000b3;
}

.fillOverlaylight {
  fill: #fff6;
}

.fillPinterestprimary {
  fill: #e00002;
}

.fillPitchblack {
  fill: #000;
}

.fillRed200 {
  fill: #fecaca;
}

.fillRed50 {
  fill: #fef2f2;
}

.fillRed700 {
  fill: #c00;
}

.fillRed900 {
  fill: #770303;
}

.fillSatan {
  fill: #666;
}

.fillShagreen200 {
  fill: #cbdbdb;
}

.fillShagreen300 {
  fill: #bacecc;
}

.fillShagreen400 {
  fill: #aabeb9;
}

.fillShagreen50 {
  fill: #edf3f3;
}

.fillShagreen500 {
  fill: #8fa8aa;
}

.fillShagreen600 {
  fill: #73919b;
}

.fillShagreen700 {
  fill: #517884;
}

.fillShagreen800 {
  fill: #2e5f6c;
}

.fillShagreen950 {
  fill: #223f4c;
}

.fillStorm {
  fill: #bbb;
}

.fillSuccessprimary {
  fill: #66a559;
}

.fillSuccesssecondary {
  fill: #2a7140;
}

.fillSuccesstertiary {
  fill: #aedfa3;
}

.fillTangerine500 {
  fill: #f9902d;
}

.fillTangerine700 {
  fill: #d26a00;
}

.fillTrademember {
  fill: #666;
}

.fillTradeprimary {
  fill: #0f8d88;
}

.fillTradesecondary {
  fill: #0d7d77;
}

.fillTradetertiary {
  fill: #e7f4f3;
}

.fillWarningprimary {
  fill: #f07f04;
}

.fillWarningsecondary {
  fill: #ffc100;
}

.fillWhite {
  fill: #fff;
}

.fillYellow100 {
  fill: #ffe9b6;
}

.fillYellow200 {
  fill: #ffdf8b;
}

.fillYellow300 {
  fill: #ffd660;
}

.fillYellow50 {
  fill: #fff4e0;
}

.fillYellow700 {
  fill: #be9116;
}

.fillYellow950 {
  fill: #5d4a20;
}

.fillCurrent {
  fill: currentColor;
}

.fillNone {
  fill: none;
}

.fillTransparent {
  fill: #0000;
}

.objectContain {
  -o-object-fit: contain;
  object-fit: contain;
}

.objectCover {
  -o-object-fit: cover;
  object-fit: cover;
}

.objectFill {
  -o-object-fit: fill;
  object-fit: fill;
}

.objectNone {
  -o-object-fit: none;
  object-fit: none;
}

.objectScaleDown {
  -o-object-fit: scale-down;
  object-fit: scale-down;
}

.objectBottom {
  -o-object-position: bottom;
  object-position: bottom;
}

.objectCenter {
  -o-object-position: center;
  object-position: center;
}

.objectLeft {
  -o-object-position: left;
  object-position: left;
}

.objectLeftBottom {
  -o-object-position: left bottom;
  object-position: left bottom;
}

.objectLeftTop {
  -o-object-position: left top;
  object-position: left top;
}

.objectRight {
  -o-object-position: right;
  object-position: right;
}

.objectRightBottom {
  -o-object-position: right bottom;
  object-position: right bottom;
}

.objectRightTop {
  -o-object-position: right top;
  object-position: right top;
}

.objectTop {
  -o-object-position: top;
  object-position: top;
}

.p0 {
  padding: 0;
}

.p1px {
  padding: 1px;
}

.p2px {
  padding: 2px;
}

.p3px {
  padding: 3px;
}

.pLarge {
  padding: 36px;
}

.pLarger {
  padding: 45px;
}

.pMedium {
  padding: 27px;
}

.pSmall {
  padding: 18px;
}

.pSmaller {
  padding: 14px;
}

.pXlarge {
  padding: 54px;
}

.pXsmall {
  padding: 9px;
}

.pXxlarge {
  padding: 72px;
}

.pXxsmall {
  padding: 5px;
}

.px0 {
  padding-left: 0;
  padding-right: 0;
}

.px1px {
  padding-left: 1px;
  padding-right: 1px;
}

.px2px {
  padding-left: 2px;
  padding-right: 2px;
}

.px3px {
  padding-left: 3px;
  padding-right: 3px;
}

.pxLarge {
  padding-left: 36px;
  padding-right: 36px;
}

.pxLarger {
  padding-left: 45px;
  padding-right: 45px;
}

.pxMedium {
  padding-left: 27px;
  padding-right: 27px;
}

.pxSmall {
  padding-left: 18px;
  padding-right: 18px;
}

.pxSmaller {
  padding-left: 14px;
  padding-right: 14px;
}

.pxXlarge {
  padding-left: 54px;
  padding-right: 54px;
}

.pxXsmall {
  padding-left: 9px;
  padding-right: 9px;
}

.pxXxlarge {
  padding-left: 72px;
  padding-right: 72px;
}

.pxXxsmall {
  padding-left: 5px;
  padding-right: 5px;
}

.py0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py1px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.py2px {
  padding-top: 2px;
  padding-bottom: 2px;
}

.py3px {
  padding-top: 3px;
  padding-bottom: 3px;
}

.pyLarge {
  padding-top: 36px;
  padding-bottom: 36px;
}

.pyLarger {
  padding-top: 45px;
  padding-bottom: 45px;
}

.pyMedium {
  padding-top: 27px;
  padding-bottom: 27px;
}

.pySmall {
  padding-top: 18px;
  padding-bottom: 18px;
}

.pySmaller {
  padding-top: 14px;
  padding-bottom: 14px;
}

.pyXlarge {
  padding-top: 54px;
  padding-bottom: 54px;
}

.pyXsmall {
  padding-top: 9px;
  padding-bottom: 9px;
}

.pyXxlarge {
  padding-top: 72px;
  padding-bottom: 72px;
}

.pyXxsmall {
  padding-top: 5px;
  padding-bottom: 5px;
}

.pb0 {
  padding-bottom: 0;
}

.pb1px {
  padding-bottom: 1px;
}

.pb2px {
  padding-bottom: 2px;
}

.pb3px {
  padding-bottom: 3px;
}

.pbLarge {
  padding-bottom: 36px;
}

.pbLarger {
  padding-bottom: 45px;
}

.pbMedium {
  padding-bottom: 27px;
}

.pbSmall {
  padding-bottom: 18px;
}

.pbSmaller {
  padding-bottom: 14px;
}

.pbXlarge {
  padding-bottom: 54px;
}

.pbXsmall {
  padding-bottom: 9px;
}

.pbXxlarge {
  padding-bottom: 72px;
}

.pbXxsmall {
  padding-bottom: 5px;
}

.pe0 {
  padding-inline-end: 0;
}

.pe1px {
  padding-inline-end: 1px;
}

.pe2px {
  padding-inline-end: 2px;
}

.pe3px {
  padding-inline-end: 3px;
}

.peLarge {
  padding-inline-end: 36px;
}

.peLarger {
  padding-inline-end: 45px;
}

.peMedium {
  padding-inline-end: 27px;
}

.peSmall {
  padding-inline-end: 18px;
}

.peSmaller {
  padding-inline-end: 14px;
}

.peXlarge {
  padding-inline-end: 54px;
}

.peXsmall {
  padding-inline-end: 9px;
}

.peXxlarge {
  padding-inline-end: 72px;
}

.peXxsmall {
  padding-inline-end: 5px;
}

.pl0 {
  padding-left: 0;
}

.pl1px {
  padding-left: 1px;
}

.pl2px {
  padding-left: 2px;
}

.pl3px {
  padding-left: 3px;
}

.plLarge {
  padding-left: 36px;
}

.plLarger {
  padding-left: 45px;
}

.plMedium {
  padding-left: 27px;
}

.plSmall {
  padding-left: 18px;
}

.plSmaller {
  padding-left: 14px;
}

.plXlarge {
  padding-left: 54px;
}

.plXsmall {
  padding-left: 9px;
}

.plXxlarge {
  padding-left: 72px;
}

.plXxsmall {
  padding-left: 5px;
}

.pr0 {
  padding-right: 0;
}

.pr1px {
  padding-right: 1px;
}

.pr2px {
  padding-right: 2px;
}

.pr3px {
  padding-right: 3px;
}

.prLarge {
  padding-right: 36px;
}

.prLarger {
  padding-right: 45px;
}

.prMedium {
  padding-right: 27px;
}

.prSmall {
  padding-right: 18px;
}

.prSmaller {
  padding-right: 14px;
}

.prXlarge {
  padding-right: 54px;
}

.prXsmall {
  padding-right: 9px;
}

.prXxlarge {
  padding-right: 72px;
}

.prXxsmall {
  padding-right: 5px;
}

.ps0 {
  padding-inline-start: 0;
}

.ps1px {
  padding-inline-start: 1px;
}

.ps2px {
  padding-inline-start: 2px;
}

.ps3px {
  padding-inline-start: 3px;
}

.psLarge {
  padding-inline-start: 36px;
}

.psLarger {
  padding-inline-start: 45px;
}

.psMedium {
  padding-inline-start: 27px;
}

.psSmall {
  padding-inline-start: 18px;
}

.psSmaller {
  padding-inline-start: 14px;
}

.psXlarge {
  padding-inline-start: 54px;
}

.psXsmall {
  padding-inline-start: 9px;
}

.psXxlarge {
  padding-inline-start: 72px;
}

.psXxsmall {
  padding-inline-start: 5px;
}

.pt0 {
  padding-top: 0;
}

.pt1px {
  padding-top: 1px;
}

.pt2px {
  padding-top: 2px;
}

.pt3px {
  padding-top: 3px;
}

.ptLarge {
  padding-top: 36px;
}

.ptLarger {
  padding-top: 45px;
}

.ptMedium {
  padding-top: 27px;
}

.ptSmall {
  padding-top: 18px;
}

.ptSmaller {
  padding-top: 14px;
}

.ptXlarge {
  padding-top: 54px;
}

.ptXsmall {
  padding-top: 9px;
}

.ptXxlarge {
  padding-top: 72px;
}

.ptXxsmall {
  padding-top: 5px;
}

.textLeft {
  text-align: left;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.textJustify {
  text-align: justify;
}

.textStart {
  text-align: start;
}

.textEnd {
  text-align: end;
}

.alignBaseline {
  vertical-align: baseline;
}

.alignTop {
  vertical-align: top;
}

.alignMiddle {
  vertical-align: middle;
}

.alignBottom {
  vertical-align: bottom;
}

.alignTextTop {
  vertical-align: text-top;
}

.alignTextBottom {
  vertical-align: text-bottom;
}

.alignSub {
  vertical-align: sub;
}

.alignSuper {
  vertical-align: super;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.normalCase {
  text-transform: none;
}

.textAlabaster {
  color: #fbfbfb;
}

.textAlertprimary {
  color: #c00;
}

.textAlertsecondary {
  color: #950808;
}

.textAlerttertiary {
  color: #fedcde;
}

.textBlue400 {
  color: #819cc5;
}

.textBlue50 {
  color: #eef1f7;
}

.textBlue700 {
  color: #375d81;
}

.textBlue800 {
  color: #204664;
}

.textBlue900 {
  color: #1b3b59;
}

.textBlue950 {
  color: #193148;
}

.textBuyerprimary {
  color: #c2a661;
}

.textBuyerprimaryiphone {
  color: #cca738;
}

.textBuyersecondary {
  color: #a48e45;
}

.textBuyertertiary {
  color: #f6f3eb;
}

.textBuyertertiaryalt {
  color: #e8dfcd;
}

.textCharcoal {
  color: #444;
}

.textCyan200 {
  color: #c6e6f1;
}

.textCyan50 {
  color: #e8f4f9;
}

.textCyan600 {
  color: #2683a3;
}

.textDealerprimary {
  color: #436b93;
}

.textDealersecondary {
  color: #204664;
}

.textDealertertiary {
  color: #dee3eb;
}

.textDealertertiaryalt {
  color: #d8ecf5;
}

.textDolphin {
  color: #ddd;
}

.textFacebookprimary {
  color: #3b5998;
}

.textGray500 {
  color: #888;
}

.textGray700 {
  color: #555;
}

.textGreen100 {
  color: #e5f4de;
}

.textGreen200 {
  color: #ceecc1;
}

.textGreen50 {
  color: #eff9ea;
}

.textGreen700 {
  color: #418f48;
}

.textGreen900 {
  color: #1b5633;
}

.textGreen950 {
  color: #0e3c1e;
}

.textInstagramprimary {
  color: #275a85;
}

.textInternalprimary {
  color: #559b5e;
}

.textInternalsecondary {
  color: #2a7140;
}

.textInternaltertiary {
  color: #b8e7c8;
}

.textIron {
  color: #888;
}

.textLinkedinprimary {
  color: #007bb6;
}

.textMarketingsale {
  color: #950808;
}

.textMedalbronze {
  color: #a06931;
}

.textMedalgold {
  color: #8c7236;
}

.textMedalplatinum {
  color: #727383;
}

.textMedalsilver {
  color: #637583;
}

.textMoonstone {
  color: #f3f3f3;
}

.textNftwine {
  color: #610034;
}

.textNoir {
  color: #222;
}

.textOverlaybackground {
  color: #000000b3;
}

.textOverlaylight {
  color: #fff6;
}

.textPinterestprimary {
  color: #e00002;
}

.textPitchblack {
  color: #000;
}

.textRed200 {
  color: #fecaca;
}

.textRed50 {
  color: #fef2f2;
}

.textRed700 {
  color: #c00;
}

.textRed900 {
  color: #770303;
}

.textSatan {
  color: #666;
}

.textShagreen200 {
  color: #cbdbdb;
}

.textShagreen300 {
  color: #bacecc;
}

.textShagreen400 {
  color: #aabeb9;
}

.textShagreen50 {
  color: #edf3f3;
}

.textShagreen500 {
  color: #8fa8aa;
}

.textShagreen600 {
  color: #73919b;
}

.textShagreen700 {
  color: #517884;
}

.textShagreen800 {
  color: #2e5f6c;
}

.textShagreen950 {
  color: #223f4c;
}

.textStorm {
  color: #bbb;
}

.textSuccessprimary {
  color: #66a559;
}

.textSuccesssecondary {
  color: #2a7140;
}

.textSuccesstertiary {
  color: #aedfa3;
}

.textTangerine500 {
  color: #f9902d;
}

.textTangerine700 {
  color: #d26a00;
}

.textTrademember {
  color: #666;
}

.textTradeprimary {
  color: #0f8d88;
}

.textTradesecondary {
  color: #0d7d77;
}

.textTradetertiary {
  color: #e7f4f3;
}

.textWarningprimary {
  color: #f07f04;
}

.textWarningsecondary {
  color: #ffc100;
}

.textWhite {
  color: #fff;
}

.textYellow100 {
  color: #ffe9b6;
}

.textYellow200 {
  color: #ffdf8b;
}

.textYellow300 {
  color: #ffd660;
}

.textYellow50 {
  color: #fff4e0;
}

.textYellow700 {
  color: #be9116;
}

.textYellow950 {
  color: #5d4a20;
}

.textCurrent {
  color: currentColor;
}

.textTransparent {
  color: #0000;
}

.contentNone {
  --tw-content: none;
  content: var(--tw-content);
}

.borderXSolid {
  border-left-style: solid;
  border-right-style: solid;
}

.borderXDashed {
  border-left-style: dashed;
  border-right-style: dashed;
}

.borderXDotted {
  border-left-style: dotted;
  border-right-style: dotted;
}

.borderXDouble {
  border-left-style: double;
  border-right-style: double;
}

.borderXHidden {
  border-left-style: hidden;
  border-right-style: hidden;
}

.borderYSolid {
  border-top-style: solid;
  border-bottom-style: solid;
}

.borderYDashed {
  border-top-style: dashed;
  border-bottom-style: dashed;
}

.borderYDotted {
  border-top-style: dotted;
  border-bottom-style: dotted;
}

.borderYDouble {
  border-top-style: double;
  border-bottom-style: double;
}

.borderYHidden {
  border-top-style: hidden;
  border-bottom-style: hidden;
}

.borderESolid {
  border-inline-end-style: solid;
}

.borderEDashed {
  border-inline-end-style: dashed;
}

.borderEDotted {
  border-inline-end-style: dotted;
}

.borderEDouble {
  border-inline-end-style: double;
}

.borderEHidden {
  border-inline-end-style: hidden;
}

.borderSSolid {
  border-inline-start-style: solid;
}

.borderSDashed {
  border-inline-start-style: dashed;
}

.borderSDotted {
  border-inline-start-style: dotted;
}

.borderSDouble {
  border-inline-start-style: double;
}

.borderSHidden {
  border-inline-start-style: hidden;
}

.borderBSolid {
  border-bottom-style: solid;
}

.borderBDashed {
  border-bottom-style: dashed;
}

.borderBDotted {
  border-bottom-style: dotted;
}

.borderBDouble {
  border-bottom-style: double;
}

.borderBHidden {
  border-bottom-style: hidden;
}

.borderLSolid {
  border-left-style: solid;
}

.borderLDashed {
  border-left-style: dashed;
}

.borderLDotted {
  border-left-style: dotted;
}

.borderLDouble {
  border-left-style: double;
}

.borderLHidden {
  border-left-style: hidden;
}

.borderRSolid {
  border-right-style: solid;
}

.borderRDashed {
  border-right-style: dashed;
}

.borderRDotted {
  border-right-style: dotted;
}

.borderRDouble {
  border-right-style: double;
}

.borderRHidden {
  border-right-style: hidden;
}

.borderTSolid {
  border-top-style: solid;
}

.borderTDashed {
  border-top-style: dashed;
}

.borderTDotted {
  border-top-style: dotted;
}

.borderTDouble {
  border-top-style: double;
}

.borderTHidden {
  border-top-style: hidden;
}

.sassyFontBody, .sassyFontBodyMedium, .sassyFontBodySizeMedium, .sassyFontBodyTypeNormal, .sassyFontBodyMediumNormal, .sassyFontBodyTypeNormalSizeMedium, .sassyFontBodySizeMediumTypeNormal, .textBodyMediumNormal {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.sassyFontBodyHeaderLarge, .sassyFontBodySizeHeaderLarge, .sassyFontBodySizeheaderLarge, .sassyFontBodyHeaderLargeNormal, .sassyFontBodySizeHeaderLargeTypeNormal, .sassyFontBodyTypeNormalSizeHeaderLarge, .textBodyHeaderLargeNormal {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 24px;
  font-weight: 300;
}

.sassyFontBodyHeaderLargeHeavy, .sassyFontBodySizeHeaderLargeTypeHeavy, .textBodyHeaderLargeHeavy {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 24px;
  font-weight: 600;
}

.sassyFontBodyHeaderLargeItalic, .sassyFontBodySizeHeaderLargeTypeItalic, .textBodyHeaderLargeItalic {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 24px;
  font-style: italic;
  font-weight: 300;
}

.sassyFontBodyHeaderMediumHeavy, .textBodyHeaderMediumHeavy {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 20px;
  font-weight: 600;
}

.sassyFontBodyHeaderMediumItalic, .textBodyHeaderMediumItalic {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 20px;
  font-style: italic;
  font-weight: 300;
}

.sassyFontBodyHeaderXxLarge, .sassyFontBodySizeHeaderXxLarge, .sassyFontBodyHeaderXxLargeNormal, .sassyFontBodySizeHeaderXxLargeTypeNormal, .textBodyHeaderXxLargeNormal {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 32px;
  font-weight: 300;
}

.sassyFontBodyHeaderXxLargeHeavy, .sassyFontBodySizeHeaderXxLargeTypeHeavy, .textBodyHeaderXxLargeHeavy {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 32px;
  font-weight: 600;
}

.sassyFontBodyHeaderXxLargeItalic, .textBodyHeaderXxLargeItalic {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 32px;
  font-style: italic;
  font-weight: 300;
}

.sassyFontBodyLarge, .sassyFontBodySizeLarge, .sassyFontBodyLargeNormal, .sassyFontBodySizeLargeTypeNormal, .textBodyLargeNormal {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 18px;
  font-weight: 300;
}

.sassyFontBodyLargeHeavy, .sassyFontBodySizeLargeTypeHeavy, .sassyFontBodyTypeHeavySizeLarge, .textBodyLargeHeavy {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 18px;
  font-weight: 600;
}

.sassyFontBodyLargeItalic, .textBodyLargeItalic {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
}

.sassyFontBodySizeHeaderLargeTypeNone {
  font-size: 24px;
  font-weight: 300;
}

.sassyFontBodySizeHeaderMedium, .sassyFontBodyHeaderMediumNormal, .textBodyHeaderMediumNormal {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 20px;
  font-weight: 300;
}

.sassyFontBodySizeHeaderXxLargeTypeNone {
  font-size: 32px;
  font-weight: 300;
}

.sassyFontBodySizeLargeTypeNone, .sassyFontBodyTypeNoneSizeLarge {
  font-size: 18px;
  font-weight: 300;
}

.sassyFontBodySizeNone, .sassyFontBodySizeNoneTypeNormal, .sassyFontBodyTypeNormalSizeNone {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
}

.sassyFontBodySizeNoneTypeItalic, .sassyFontBodyTypeItalicSizeNone {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-style: italic;
  font-weight: 300;
}

.sassyFontBodySizeNoneTypeNone, .sassyFontSubHeaderTypeNoneSizeNone {
  font-weight: 300;
}

.sassyFontBodySizeXSmallTypeNone, .sassyFontBodyTypeNoneSizeXSmall {
  font-size: 12px;
  font-weight: 300;
}

.sassyFontBodySizeXxSmall, .sassyFontBodyXxSmallNormal, .textBodyXxSmallNormal {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 10px;
  font-weight: 300;
}

.sassyFontBodySmall, .sassyFontBodySizeSmall, .sassyFontBodySmallNormal, .sassyFontBodyTypeNormalSizeSmall, .sassyFontBodySizeSmallTypeNormal, .textBodySmallNormal {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.sassyFontBodySmallHeavy, .sassyFontBodySizeSmallTypeHeavy, .sassyFontBodyTypeHeavySizeSmall, .sassyFontBodyTypeHeavySizesmall, .sassyFontBodySizeSmallTypeheavy, .textBodySmallHeavy {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
}

.sassyFontBodySmallItalic, .sassyFontBodySizeSmallTypeItalic, .sassyFontBodyTypeItalicSizeSmall, .textBodySmallItalic {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
}

.sassyFontBodyTypeHeavy, .sassyFontBodyTypeheavy, .sassyFontBodyMediumHeavy, .sassyFontBodySizeMediumTypeHeavy, .sassyFontBodyTypeHeavySizeMedium, .textBodyMediumHeavy {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 600;
}

.sassyFontBodyTypeHeavySizeNone, .sassyFontBodySizeNoneTypeHeavy {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 600;
}

.sassyFontBodyTypeItalic, .sassyFontBodyMediumItalic, .textBodyMediumItalic {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
}

.sassyFontBodyTypeNone, .sassyFontBodySizeMediumTypeNone, .sassyFontBodyTypeNoneSizeMedium {
  font-size: 16px;
  font-weight: 300;
}

.sassyFontBodyXSmall, .sassyFontBodySizeXSmall, .sassyFontBodySizexSmall, .sassyFontBodyXSmallNormal, .sassyFontBodySizeXSmallTypeNormal, .textBodyXSmallNormal {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
}

.sassyFontBodyXSmallHeavy, .sassyFontBodySizeXSmallTypeHeavy, .sassyFontBodyTypeHeavySizeXSmall, .textBodyXSmallHeavy {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 600;
}

.sassyFontBodyXSmallItalic, .sassyFontBodyTypeItalicSizeXSmall, .sassyFontBodySizeXSmallTypeItalic, .textBodyXSmallItalic {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
}

.sassyFontBodyXxSmallHeavy, .textBodyXxSmallHeavy {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 10px;
  font-weight: 600;
}

.sassyFontBodyXxSmallItalic, .textBodyXxSmallItalic {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 10px;
  font-style: italic;
  font-weight: 300;
}

.sassyFontHeader, .sassyFontHeaderLarge, .sassyFontHeaderSizeLarge, .sassyFontHeaderLargeNormal, .sassyFontHeaderSizeLargeTypeNormal, .textHeaderLargeNormal {
  letter-spacing: -.5px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.sassyFontHeaderMedium, .sassyFontHeaderSizeMedium, .sassyFontHeaderMediumNormal, .sassyFontHeaderSizeMediumTypeNormal, .textHeaderMediumNormal {
  letter-spacing: -.5px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.sassyFontHeaderMediumItalic, .sassyFontHeaderSizeMediumTypeItalic, .sassyFontHeaderTypeItalicSizeMedium, .textHeaderMediumItalic {
  letter-spacing: 0;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 20px;
  font-style: italic;
  font-weight: normal;
  line-height: 1.4;
}

.sassyFontHeaderNoneItalic, .sassyFontHeaderSizeNoneTypeItalic, .sassyFontHeaderTypeItalicSizeNone {
  letter-spacing: 0;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-style: italic;
  font-weight: normal;
}

.sassyFontHeaderSizeNone {
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-style: normal;
  font-weight: normal;
}

.sassyFontHeaderSizeXLargeTypeNone, .sassyFontHeaderTypeNoneSizeXLarge {
  font-style: none;
  font-size: 28px;
  font-weight: normal;
  line-height: 1.4;
}

.sassyFontHeaderSizeXxLargeTypeNone, .sassyFontHeaderTypeNoneSizeXxLarge {
  font-style: none;
  font-size: 32px;
  font-weight: normal;
  line-height: 1.4;
}

.sassyFontHeaderSmall, .sassyFontHeaderSizeSmall, .sassyFontHeaderSizesmall, .sassyFontHeaderSmallNormal, .sassyFontHeaderSizeSmallTypeNormal, .textHeaderSmallNormal {
  letter-spacing: -.5px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.sassyFontHeaderSmallItalic, .sassyFontHeaderSizeSmallTypeItalic, .textHeaderSmallItalic {
  letter-spacing: 0;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 16px;
  font-style: italic;
  font-weight: normal;
  line-height: 1.4;
}

.sassyFontHeaderTypeItalic, .sassyFontHeaderLargeItalic, .sassyFontHeaderSizeLargeTypeItalic, .textHeaderLargeItalic {
  letter-spacing: 0;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 24px;
  font-style: italic;
  font-weight: normal;
  line-height: 1.4;
}

.sassyFontHeaderTypeNone, .sassyFontHeaderSizeLargeTypeNone, .sassyFontHeaderTypeNoneSizeLarge {
  font-style: none;
  font-size: 24px;
  font-weight: normal;
  line-height: 1.4;
}

.sassyFontHeaderTypeNoneSizeMedium, .sassyFontHeaderSizeMediumTypeNone {
  font-style: none;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.4;
}

.sassyFontHeaderTypeNoneSizeSmall {
  font-style: none;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.4;
}

.sassyFontHeaderVinDiesel, .sassyFontHeaderSizeVinDiesel, .sassyFontHeaderVinDieselNormal, .textHeaderVinDieselNormal {
  letter-spacing: -1px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 52px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.sassyFontHeaderVinDieselItalic, .sassyFontHeaderSizeVinDieselTypeItalic, .textHeaderVinDieselItalic {
  letter-spacing: 0;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 52px;
  font-style: italic;
  font-weight: normal;
  line-height: 1.4;
}

.sassyFontHeaderXLarge, .sassyFontHeaderSizeXLarge, .sassyFontHeaderXLargeNormal, .sassyFontHeaderSizeXLargeTypeNormal, .textHeaderXLargeNormal {
  letter-spacing: -.5px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 28px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.sassyFontHeaderXLargeItalic, .sassyFontHeaderSizeXLargeTypeItalic, .textHeaderXLargeItalic {
  letter-spacing: 0;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 28px;
  font-style: italic;
  font-weight: normal;
  line-height: 1.4;
}

.sassyFontHeaderXSmall, .sassyFontHeaderSizeXSmall, .sassyFontHeaderSizexSmall, .sassyFontHeaderXSmallNormal, .sassyFontHeaderSizeXSmallTypeNormal, .textHeaderXSmallNormal {
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
}

.sassyFontHeaderXSmallItalic, .textHeaderXSmallItalic {
  letter-spacing: 0;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 14px;
  font-style: italic;
  font-weight: normal;
}

.sassyFontHeaderXxLarge, .sassyFontHeaderSizeXxLarge, .sassyFontHeaderXxLargeNormal, .sassyFontHeaderSizeXxLargeTypeNormal, .textHeaderXxLargeNormal {
  letter-spacing: -.5px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 32px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.sassyFontHeaderXxLargeItalic, .sassyFontHeaderSizeXxLargeTypeItalic, .textHeaderXxLargeItalic {
  letter-spacing: 0;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 32px;
  font-style: italic;
  font-weight: normal;
  line-height: 1.4;
}

.sassyFontHeaderXxxxLargeItalic, .textHeaderXxxxLargeItalic {
  letter-spacing: 0;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 80px;
  font-style: italic;
  font-weight: normal;
  line-height: 1.1;
}

.sassyFontHeaderXxxxLargeNormal, .textHeaderXxxxLargeNormal {
  letter-spacing: -2px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 80px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.1;
}

.sassyFontSubHeader, .sassyFontSubHeaderMedium, .sassyFontSubHeaderTypeHeavy, .sassyFontSubHeaderSizeMedium, .sassyFontSubHeaderMediumHeavy, .sassyFontSubHeaderSizeMediumTypeHeavy, .textSubHeaderMediumHeavy {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
}

.sassyFontSubHeaderSizeNone {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 600;
}

.sassyFontSubHeaderSmall, .sassyFontSubHeaderSizeSmall, .sassyFontSubHeaderSmallHeavy, .sassyFontSubHeaderSizeSmallTypeHeavy, .sassyFontSubHeaderTypeHeavySizeSmall, .textSubHeaderSmallHeavy {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 600;
}

.sassyFontSubHeaderSmallNormal, .sassyFontSubHeaderTypeNormalSizeSmall, .sassyFontSubHeaderSizeSmallTypeNormal, .textSubHeaderSmallNormal {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
}

.sassyFontSubHeaderTypeNone, .sassyFontBodySizeSmallTypeNone, .sassyFontBodyTypeNoneSizeSmall {
  font-size: 14px;
  font-weight: 300;
}

.sassyFontSubHeaderTypeNormal, .sassyFontSubHeaderMediumNormal, .sassyFontSubHeaderSizeMediumTypeNormal, .textSubHeaderMediumNormal {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.sassyFontSubHeaderTypeNormalSizeNone, .sassyFontSubHeaderSizeNoneTypeNormal {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
}

.inset0 {
  inset: 0;
}

.inset50 {
  inset: 50%;
}

.inset33 {
  inset: 33.3333%;
}

.inset25 {
  inset: 25%;
}

.inset1px {
  inset: 1px;
}

.inset67 {
  inset: 66.6667%;
}

.inset2px {
  inset: 2px;
}

.inset75 {
  inset: 75%;
}

.inset3px {
  inset: 3px;
}

.insetLarge {
  inset: 36px;
}

.insetLarger {
  inset: 45px;
}

.insetMedium {
  inset: 27px;
}

.insetSmall {
  inset: 18px;
}

.insetSmaller {
  inset: 14px;
}

.insetXlarge {
  inset: 54px;
}

.insetXsmall {
  inset: 9px;
}

.insetXxlarge {
  inset: 72px;
}

.insetXxsmall {
  inset: 5px;
}

.insetAuto {
  inset: auto;
}

.insetFull {
  inset: 100%;
}

.insetX0 {
  left: 0;
  right: 0;
}

.insetX50 {
  left: 50%;
  right: 50%;
}

.insetX33 {
  left: 33.3333%;
  right: 33.3333%;
}

.insetX25 {
  left: 25%;
  right: 25%;
}

.insetX1px {
  left: 1px;
  right: 1px;
}

.insetX67 {
  left: 66.6667%;
  right: 66.6667%;
}

.insetX2px {
  left: 2px;
  right: 2px;
}

.insetX75 {
  left: 75%;
  right: 75%;
}

.insetX3px {
  left: 3px;
  right: 3px;
}

.insetXLarge {
  left: 36px;
  right: 36px;
}

.insetXLarger {
  left: 45px;
  right: 45px;
}

.insetXMedium {
  left: 27px;
  right: 27px;
}

.insetXSmall {
  left: 18px;
  right: 18px;
}

.insetXSmaller {
  left: 14px;
  right: 14px;
}

.insetXXlarge {
  left: 54px;
  right: 54px;
}

.insetXXsmall {
  left: 9px;
  right: 9px;
}

.insetXXxlarge {
  left: 72px;
  right: 72px;
}

.insetXXxsmall {
  left: 5px;
  right: 5px;
}

.insetXAuto {
  left: auto;
  right: auto;
}

.insetXFull {
  left: 100%;
  right: 100%;
}

.insetY0 {
  top: 0;
  bottom: 0;
}

.insetY50 {
  top: 50%;
  bottom: 50%;
}

.insetY33 {
  top: 33.3333%;
  bottom: 33.3333%;
}

.insetY25 {
  top: 25%;
  bottom: 25%;
}

.insetY1px {
  top: 1px;
  bottom: 1px;
}

.insetY67 {
  top: 66.6667%;
  bottom: 66.6667%;
}

.insetY2px {
  top: 2px;
  bottom: 2px;
}

.insetY75 {
  top: 75%;
  bottom: 75%;
}

.insetY3px {
  top: 3px;
  bottom: 3px;
}

.insetYLarge {
  top: 36px;
  bottom: 36px;
}

.insetYLarger {
  top: 45px;
  bottom: 45px;
}

.insetYMedium {
  top: 27px;
  bottom: 27px;
}

.insetYSmall {
  top: 18px;
  bottom: 18px;
}

.insetYSmaller {
  top: 14px;
  bottom: 14px;
}

.insetYXlarge {
  top: 54px;
  bottom: 54px;
}

.insetYXsmall {
  top: 9px;
  bottom: 9px;
}

.insetYXxlarge {
  top: 72px;
  bottom: 72px;
}

.insetYXxsmall {
  top: 5px;
  bottom: 5px;
}

.insetYAuto {
  top: auto;
  bottom: auto;
}

.insetYFull {
  top: 100%;
  bottom: 100%;
}

.bottom0 {
  bottom: 0;
}

.bottom50 {
  bottom: 50%;
}

.bottom33 {
  bottom: 33.3333%;
}

.bottom25 {
  bottom: 25%;
}

.bottom1px {
  bottom: 1px;
}

.bottom67 {
  bottom: 66.6667%;
}

.bottom2px {
  bottom: 2px;
}

.bottom75 {
  bottom: 75%;
}

.bottom3px {
  bottom: 3px;
}

.bottomLarge {
  bottom: 36px;
}

.bottomLarger {
  bottom: 45px;
}

.bottomMedium {
  bottom: 27px;
}

.bottomSmall {
  bottom: 18px;
}

.bottomSmaller {
  bottom: 14px;
}

.bottomXlarge {
  bottom: 54px;
}

.bottomXsmall {
  bottom: 9px;
}

.bottomXxlarge {
  bottom: 72px;
}

.bottomXxsmall {
  bottom: 5px;
}

.bottomAuto {
  bottom: auto;
}

.bottomFull {
  bottom: 100%;
}

.left0 {
  left: 0;
}

.left50 {
  left: 50%;
}

.left33 {
  left: 33.3333%;
}

.left25 {
  left: 25%;
}

.left1px {
  left: 1px;
}

.left67 {
  left: 66.6667%;
}

.left2px {
  left: 2px;
}

.left75 {
  left: 75%;
}

.left3px {
  left: 3px;
}

.leftLarge {
  left: 36px;
}

.leftLarger {
  left: 45px;
}

.leftMedium {
  left: 27px;
}

.leftSmall {
  left: 18px;
}

.leftSmaller {
  left: 14px;
}

.leftXlarge {
  left: 54px;
}

.leftXsmall {
  left: 9px;
}

.leftXxlarge {
  left: 72px;
}

.leftXxsmall {
  left: 5px;
}

.leftAuto {
  left: auto;
}

.leftFull {
  left: 100%;
}

.right0 {
  right: 0;
}

.right50 {
  right: 50%;
}

.right33 {
  right: 33.3333%;
}

.right25 {
  right: 25%;
}

.right1px {
  right: 1px;
}

.right67 {
  right: 66.6667%;
}

.right2px {
  right: 2px;
}

.right75 {
  right: 75%;
}

.right3px {
  right: 3px;
}

.rightLarge {
  right: 36px;
}

.rightLarger {
  right: 45px;
}

.rightMedium {
  right: 27px;
}

.rightSmall {
  right: 18px;
}

.rightSmaller {
  right: 14px;
}

.rightXlarge {
  right: 54px;
}

.rightXsmall {
  right: 9px;
}

.rightXxlarge {
  right: 72px;
}

.rightXxsmall {
  right: 5px;
}

.rightAuto {
  right: auto;
}

.rightFull {
  right: 100%;
}

.top0 {
  top: 0;
}

.top50 {
  top: 50%;
}

.top33 {
  top: 33.3333%;
}

.top25 {
  top: 25%;
}

.top1px {
  top: 1px;
}

.top67 {
  top: 66.6667%;
}

.top2px {
  top: 2px;
}

.top75 {
  top: 75%;
}

.top3px {
  top: 3px;
}

.topLarge {
  top: 36px;
}

.topLarger {
  top: 45px;
}

.topMedium {
  top: 27px;
}

.topSmall {
  top: 18px;
}

.topSmaller {
  top: 14px;
}

.topXlarge {
  top: 54px;
}

.topXsmall {
  top: 9px;
}

.topXxlarge {
  top: 72px;
}

.topXxsmall {
  top: 5px;
}

.topAuto {
  top: auto;
}

.topFull {
  top: 100%;
}

.opacity0 {
  opacity: 0;
}

.opacity100 {
  opacity: 1;
}

.opacity50 {
  opacity: .5;
}

.opacity80 {
  opacity: .8;
}
