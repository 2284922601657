.button {
  line-height: unset;
  cursor: pointer;
  color: #fff;
  background: #2e5f6c;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
  margin: 0 0 0 auto;
  padding: 0;
  transition: background-color .3s;
  display: flex;
  box-shadow: 1px 3px 30px #0000002e;
}

@media (width >= 1025px) {
  .button {
    border: 2px solid #fff;
    border-radius: 100px;
    width: auto;
    height: 48px;
    padding: 12px 18px;
    font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}

.button:hover {
  background-color: #223f4c;
}

@media print {
  .button {
    display: none;
  }
}

.hidden {
  display: none;
}

.enter {
  animation: .6s cubic-bezier(.44, 0, .12, 1.02) enterFromBottom;
}

.chatIcon {
  width: 24px;
  height: 24px;
}

@media (width >= 1025px) {
  .chatIcon {
    width: 18px;
    height: 18px;
  }
}

.chatLabel {
  display: none;
}

@media (width >= 1025px) {
  .chatLabel {
    -webkit-user-select: none;
    user-select: none;
    margin-left: 9px;
    display: block;
  }
}

.container {
  z-index: 200;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
  position: fixed;
  inset: auto 14px 14px auto;
}

@media (width >= 1025px) {
  .container {
    align-items: flex-start;
    bottom: 18px;
    right: 18px;
  }
}

@keyframes enterFromBottom {
  0% {
    opacity: 0;
    transform: translateY(8px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
