.container {
  display: flex;
}

.logo {
  color: #fff;
  width: 85px;
  margin: auto 5px;
  overflow: visible !important;
  height: 100%;
}
.logo.navPrivateClient {
  width: 83px;
}

.dark {
  color: #222;
}

.black {
  width: 87px;
  color: #000;
}