.link {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  line-height: unset;
  color: #000;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: underline;
}
.link:hover {
  color: #444;
}
@media (hover: none) {
  .link:hover {
    color: #000;
  }
}

.standardLink {
  /*! keep */
  color: #000;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: underline;
}
.standardLink:hover {
  color: #444;
}
@media (hover: none) {
  .standardLink:hover {
    color: #000;
  }
}

.invertedLink {
  /*! keep */
  color: #444;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: none;
}
.invertedLink:hover {
  color: #444;
  text-decoration: underline;
}

.contrastLinkLightInverse {
  /*! keep */
  text-decoration: none;
  color: #fff;
}
.contrastLinkLightInverse:hover {
  color: #fff;
  text-decoration: underline;
}

.contrastLinkLight {
  /*! keep */
  text-decoration: none;
  color: #fff;
}
.contrastLinkLight a:hover, .contrastLinkLight:hover {
  color: #fff;
  text-decoration: underline;
}

.contrastLinkAlternate {
  /*! keep */
  text-decoration: none;
  color: #bbb;
}
.contrastLinkAlternate:hover {
  color: #bbb;
  text-decoration: underline;
}

.contrastLinkNoEffect {
  /*! keep */
  text-decoration: none;
  color: #fff;
}
.contrastLinkNoEffect:hover {
  color: #fff;
}

.standardLinkNoEffect {
  /*! keep */
  text-decoration: none;
  color: #000;
}
.standardLinkNoEffect:hover {
  color: #000;
}

.underline {
  text-decoration: underline;
}

.dashed {
  text-decoration: underline dashed 0.5px;
}

.none {
  /*! keep */
  text-decoration: none;
}

.linkNoDecoration {
  /*! keep */
  text-decoration: none;
}