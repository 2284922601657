.container {
  display: flex;
}

.logo {
  color: #fff;
  width: 85px;
  height: 100%;
  margin: auto 5px;
  overflow: visible !important;
}

.logo.navPrivateClient {
  width: 83px;
}

.dark {
  color: #222;
}

.black {
  color: #000;
  width: 87px;
}
