:local .title {
  margin: 0;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-style: normal;
  font-weight: normal;
}

:local .title.small {
  font-style: none;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.4;
}

:local .title.medium {
  font-style: none;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.4;
}

:local .title.large {
  font-style: none;
  font-size: 24px;
  font-weight: normal;
  line-height: 1.4;
}

:local .title.xLarge {
  font-style: none;
  font-size: 28px;
  font-weight: normal;
  line-height: 1.4;
}

:local .title.xxLarge {
  font-style: none;
  font-size: 32px;
  font-weight: normal;
  line-height: 1.4;
}

:local .subtitle {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
}

:local .subtitle.small {
  margin-top: 0;
  font-size: 12px;
  font-weight: 300;
}

:local .subtitle.medium {
  margin-top: 0;
  font-size: 14px;
  font-weight: 300;
}

:local .subtitle.large {
  margin-top: 9px;
  font-size: 16px;
  font-weight: 300;
}

:is(:local .subtitle.xLarge, :local .subtitle.xxLarge) {
  margin-top: 9px;
  font-size: 18px;
  font-weight: 300;
}

:local .subtitle.xxSmallSpacing {
  margin-top: 5px;
}

:local .subtitle.xSmallSpacing {
  margin-top: 9px;
}
